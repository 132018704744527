@import '~styles/mixins.sass';

.leaderboard-container {
	// height: 100%
	padding: 0 10px;
	@include display-flex-center-mixin; }


.leaderboard-image-container {
	width: 24px;
	height: 24px;
	@include display-flex-center-mixin;
	border-radius: 50%;
	cursor: pointer; }

.leaderboard-image {
	width: 10px;
	height: 10px; }

.leaderboard-text-container {
	padding: 0 20px 0 5px;
	@include display-flex-center-mixin; }

.show-leaderboard-hover {
	display: flex;
	justify-content: space-around;
	align-items: center;
	position : absolute;
	top: 50px;
	padding: 2px 8px;
	border-radius: 6px;
	text-align: center;
	height: 20px;
	z-index: 2; }

.arrow {
	width: 6px;
	height: 6px;
	position: absolute;
	right: 132.5px;
	bottom: 0px;
	box-sizing: border-box;
	z-index: 2; }

@media ( min-width: 1440px ) {
	.leaderboard-container {
		padding: 0 11.25px; }


	.leaderboard-image-container {
		width: 27px;
		height: 27px; }

	.leaderboard-image {
		width: 11.25px;
		height: 11.25px; }

	.leaderboard-text-container {
		padding: 0 22.5px 0 5.625px; }

	.show-leaderboard-hover {
		top: 56.25px;
		padding: 2.25px 9px;
		border-radius: 6.75px;
		height: 22.5px; }

	.arrow {
		width: 6.75px;
		height: 6.75px;
		right: 135.5px; } }

@media ( min-width: 1600px ) {
	.leaderboard-container {
		padding: 0 12.5px; }


	.leaderboard-image-container {
		width: 30px;
		height: 30px; }

	.leaderboard-image {
		width: 12.5px;
		height: 12.5px; }

	.leaderboard-text-container {
		padding: 0 24px 0 7.5px; }

	.show-leaderboard-hover {
		top: 62.5px;
		padding: 2.5px 10px;
		border-radius: 7.5px;
		height: 25px; }

	.arrow {
		width: 7.5px;
		height: 7.5px;
		right: 165.625px; } }

@media ( min-width: 1920px ) {
	.leaderboard-container {
		padding: 0 15px; }


	.leaderboard-image-container {
		width: 36px;
		height: 36px; }

	.leaderboard-image {
		width: 15px;
		height: 15px; }

	.leaderboard-text-container {
		padding: 0 30px 0 7.5px; }

	.show-leaderboard-hover {
		top: 75px;
		padding: 3px 12px;
		border-radius: 9px;
		height: 30px; }

	.arrow {
		width: 9px;
		height: 9px;
		right: 190px; } }

@media ( min-width: 2560px ) {
	.leaderboard-container {
		padding: 0 20px; }


	.leaderboard-image-container {
		width: 48px;
		height: 48px; }

	.leaderboard-image {
		width: 20px;
		height: 20px; }

	.leaderboard-text-container {
		padding: 0 38.4px 0 12px; }

	.show-leaderboard-hover {
		top: 100px;
		padding: 4px 16px;
		border-radius: 12px;
		height: 40px; }

	.arrow {
		width: 12px;
		height: 12px;
		right: 245px; } }
