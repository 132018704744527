@import '~styles/mixins.sass';

.leaderboard-container {
	width: 1000px;
	height: 500px;
	overflow: hidden;
	border-radius: 5px; }

.header-container {
	width: 100%;
	height: 44px;
	display: flex; }

.header-line {
	width: calc(100% - 8px);
	height: 1px;
	margin: 0 4px; }

.header-content {
	width: calc(100% - 164px);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding-left: 10px; }

.refresh-container {
	width: 120px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer; }

.refresh-image {
	width: 13px;
	height: 13px; }

.refresh-text {
	padding-left: 4px; }

.header-close {
	width: 44px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 5px;
	cursor: pointer; }

.content-container {
	width: 100%;
	height: calc(100% - 45px); }

.close-image {
	width: 17px;
	height: 18px; }

.loading-container {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin; }

.leaderboard-users-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 0 5px; }

.users-container {
	width: 100%;
	height: calc(100% - 25px);
	box-sizing: border-box;
	overflow-y: auto;
 }	// padding: 0 5px

.leaderboard-header {
	width: 100%;
	height: 25px;
	display: flex;
	justify-content: space-between; }

.leaderboard-user {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between; }

.rank {
	width: 10%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 10px;
	box-sizing: border-box; }

.user-name {
	width: 35%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	box-sizing: border-box;
	padding-right: 10px;
	position: relative; }

.metrics {
	width: 15%;
	height: 100%;
	@include display-flex-center-mixin;
	box-sizing: border-box;
	padding-right: 10px; }

.metric-scores {
	width: 30%;
	height: 100%;
	@include display-flex-center-mixin;
	box-sizing: border-box;
	padding-right: 10px; }

.day {
	width: 15%;
	height: 100%;
	@include display-flex-center-mixin; }

.you-arrow {
	width: 0px;
	height: 0px;
	position: absolute;
	right: -30px;
	top: 0; }

.user-status {
	padding: 3px 8px;
	margin-top: 7px;
	border-radius: 10px; }

.metric-score-box {
	width: 100%;
	height: 40px;
	@include display-flex-center-mixin; }

.leaderboard-svg {
	padding-left: 30px;
	padding-right: 20px; }

@media ( min-width: 1440px ) {
	.leaderboard-container {
		width: 1125px;
		height: 562.5px;
		border-radius: 5.625px; }

	.header-container {
		height: 49.5px; }

	.header-line {
		width: calc(100% - 9px);
		height: 1.125px;
		margin: 0 4.5px; }

	.header-content {
		width: calc(100% - 184.5px);
		padding-left: 11.25px; }

	.refresh-container {
		width: 135px; }

	.refresh-image {
		width: 14.625px;
		height: 14.625px; }

	.refresh-text {
		padding-left: 4.5px; }

	.header-close {
		width: 49.5px;
		border-bottom-left-radius: 5.625px; }

	.content-container {
		height: calc(100% - 50.625px); }

	.close-image {
		width: 19.125px;
		height: 20.25px; }

	.leaderboard-users-container {
		padding: 0 5.625px; }

	.users-container {
		height: calc(100% - 28.125px);
 }		// padding: 0 5px

	.leaderboard-header {
		height: 28.125px; }

	.leaderboard-user {
		height: 67.5px; }

	.rank {
		padding-right: 11.25px; }

	.user-name {
		padding-right: 11.25px; }

	.metrics {
		padding-right: 11.25px; }

	.metric-scores {
		padding-right: 11.25px; }

	.you-arrow {
		right: -33.75px;
		top: 0; }

	.user-status {
		padding: 3.375px 9px;
		margin-top: 7.875px;
		border-radius: 11.25px; }

	.metric-score-box {
		height: 45px; }

	.leaderboard-svg {
		padding-left: 33.75px;
		padding-right: 22.5px; } }

@media ( min-width: 1600px ) {
	.leaderboard-container {
		width: 1250px;
		height: 625px;
		border-radius: 6.25px; }

	.header-container {
		height: 55px; }

	.header-line {
		width: calc(100% - 10px);
		height: 1.25px;
		margin: 0 5px; }

	.header-content {
		width: calc(100% - 205px);
		padding-left: 10px; }

	.refresh-container {
		width: 150px; }

	.refresh-image {
		width: 16.25px;
		height: 16.25px; }

	.refresh-text {
		padding-left: 5px; }

	.header-close {
		width: 55px;
		border-bottom-left-radius: 6.25px; }

	.content-container {
		height: calc(100% - 56.25px); }

	.close-image {
		width: 21.25px;
		height: 22.5px; }

	.leaderboard-users-container {
		padding: 0 6.25px; }

	.users-container {
		height: calc(100% - 31.25px);
 }		// padding: 0 6.25px

	.leaderboard-header {
		height: 31.25px; }

	.leaderboard-user {
		height: 75px; }

	.rank {
		padding-right: 12.5px; }

	.user-name {
		padding-right: 12.5px; }

	.metrics {
		padding-right: 12.5px; }

	.metric-scores {
		padding-right: 12.5px; }

	.you-arrow {
		right: -37.5px;
		top: 0; }

	.user-status {
		padding: 3.75px 10px;
		margin-top: 8.75px;
		border-radius: 12.5px; }

	.metric-score-box {
		height: 50px; }

	.leaderboard-svg {
		padding-left: 37.5px;
		padding-right: 25px; } }

@media ( min-width: 1920px ) {
	.leaderboard-container {
		width: 1500px;
		height: 750px;
		border-radius: 7.5px; }

	.header-container {
		height: 66px; }

	.header-line {
		width: calc(100% - 12px);
		height: 1.5px;
		margin: 0 6px; }

	.header-content {
		width: calc(100% - 246px);
		padding-left: 15px; }

	.refresh-container {
		width: 180px; }

	.refresh-image {
		width: 19.5px;
		height: 19.5px; }

	.refresh-text {
		padding-left: 6px; }

	.header-close {
		width: 66px;
		border-bottom-left-radius: 7.5px; }

	.content-container {
		height: calc(100% - 67.5px); }

	.close-image {
		width: 25.5px;
		height: 27px; }

	.leaderboard-users-container {
		padding: 0 7.5px; }

	.users-container {
		height: calc(100% - 37.5px);
 }		// padding: 0 5px

	.leaderboard-header {
		height: 37.5px; }

	.leaderboard-user {
		height: 90px; }

	.rank {
		padding-right: 15px; }

	.user-name {
		padding-right: 15px; }

	.metrics {
		padding-right: 15px; }

	.metric-scores {
		padding-right: 15px; }

	.you-arrow {
		right: -45px;
		top: 0; }

	.user-status {
		padding: 4.5px 12px;
		margin-top: 10.5px;
		border-radius: 15px; }

	.metric-score-box {
		height: 60px; }

	.leaderboard-svg {
		padding-left: 45px;
		padding-right: 30px; } }

@media ( min-width: 2560px ) {
	.leaderboard-container {
		width: 2000px;
		height: 1000px;
		border-radius: 10px; }

	.header-container {
		height: 88px; }

	.header-line {
		width: calc(100% - 16px);
		height: 2px;
		margin: 0 8px; }

	.header-content {
		width: calc(100% - 328px);
		padding-left: 16px; }

	.refresh-container {
		width: 240px; }

	.refresh-image {
		width: 26px;
		height: 26px; }

	.refresh-text {
		padding-left: 8px; }

	.header-close {
		width: 55px;
		border-bottom-left-radius: 10px; }

	.content-container {
		height: calc(100% - 90px); }

	.close-image {
		width: 34px;
		height: 36px; }

	.leaderboard-users-container {
		padding: 0 10px; }

	.users-container {
		height: calc(100% - 50px);
 }		// padding: 0 10px

	.leaderboard-header {
		height: 50px; }

	.leaderboard-user {
		height: 120px; }

	.rank {
		padding-right: 20px; }

	.user-name {
		padding-right: 20px; }

	.metrics {
		padding-right: 20px; }

	.metric-scores {
		padding-right: 20px; }

	.you-arrow {
		right: -60px;
		top: 0; }

	.user-status {
		padding: 6px 16px;
		margin-top: 14px;
		border-radius: 20px; }

	.metric-score-box {
		height: 80px; }

	.leaderboard-svg {
		padding-left: 60px;
		padding-right: 40px; } }
