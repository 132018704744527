@import '~styles/mixins.sass';

.graph-box-container {
	width: 100%; }

.header-container {
	width: 100%;
	height: 62px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 10px; }

.header-container-actor-adoption {
	width: 100%;
	height: 62px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 0px 10px 10px 10px; }

.graph-container {
	width: 92%;
	// height: 240px
	box-sizing: border-box;
	padding: 10px 0; }

.graph-container-actor-adoption {
	width: 92%;
	// height: 240px
	box-sizing: border-box;
	padding: 35px 0 10px 0; }

.text-container {
	width: 100%;
	box-sizing: border-box;
	padding: 15px; }

.main-heading-container {
	width: 100%;
	@include display-flex-center-mixin; }

.sub-heading-container {
	width: 100%;
	padding-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center; }

.sub-heading {
	padding-right: 10px; }

.header-metric {
	padding-left: 10px; }

.header-metric-title {
	padding-left: 2px; }

.header-metric-value {
	padding-left: 7px; }

.filters-container {
	margin-top: 5px;
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-around; }

.filters-container-actor-adoption {
	margin-top: -20px;
	margin-right: auto;
	margin-left: auto;
	width: 90%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 5px; }

.filter-container {
	display: flex;
	height: 100%;
	align-items: center;
	margin-left: 10px; }

.filter-circle {
	width: 12px;
	height: 12px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content:  center; }

.filter-text {
	padding-left: 5px; }

.filter-selected {
	width: 4px;
	height: 4px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer; }

.legends-container {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 23px;
	padding: 0 10px;
	@include display-flex-center-mixin; }

.each-legend {
	@include display-flex-center-mixin; }

.legend-color {
	width: 15px;
	height: 5px; }

.legend-color-point {
	width: 9px;
	height: 9px;
	box-sizing: border-box;
	border: 2px solid red;
	border-radius: 50%; }

.legend-text {
	padding-left: 7px;
	padding-right: 10px; }

@media ( min-width: 1440px ) {
	.header-container {
		height: 69.75px;
		padding: 11.25px; }

	.header-container-actor-adoption {
		height: 69.75px;
		padding: 0px 11.25px 11.25px 11.25px; }

	.graph-container {
		padding: 11.25px 0; }

	.graph-container-actor-adoption {
		padding: 39.375px 0 11.25px 0; }

	.text-container {
		padding: 16.875px; }

	.sub-heading-container {
		padding-top: 5.625px; }

	.sub-heading {
		padding-right: 11.25px; }

	.header-metric {
		padding-left: 11.25px; }

	.header-metric-title {
		padding-left: 2.25px; }

	.header-metric-value {
		padding-left: 7.875px; }

	.filters-container {
		margin-top: 5.625px;
		height: 45px; }

	.filters-container-actor-adoption {
		margin-top: -22.5px;
		height: 45px;
		border-radius: 5.625px; }

	.filter-container {
		margin-left: 11.25px; }

	.filter-circle {
		width: 13.5px;
		height: 13.5px; }

	.filter-text {
		padding-left: 5.625px; }

	.filter-selected {
		width: 4.5px;
		height: 4.5px; }

	.legends-container {
		margin-bottom: 25.875px;
		padding: 0 11.25px; }

	.legend-color {
		width: 16.875px;
		height: 5.625px; }

	.legend-color-point {
		width: 10.125px;
		height: 10.125px;
		border: 2.25px solid red; }

	.legend-text {
		padding-left: 7.875px;
		padding-right: 11.25px; } }

@media ( min-width: 1600px ) {
	.header-container {
		height: 77.5px;
		padding: 12.5px; }

	.header-container-actor-adoption {
		padding: 0px 12.5px 12.5px 12.5px;
		height: 77.5px; }

	.graph-container {
		padding: 12.5px 0; }

	.graph-container-actor-adoption {
		padding: 43.75px 0 12.5px 0; }

	.text-container {
		padding: 18.75px; }

	.sub-heading-container {
		padding-top: 6.25px; }

	.sub-heading {
		padding-right: 12.5px; }

	.header-metric {
		padding-left: 12.5px; }

	.header-metric-title {
		padding-left: 2.5px; }

	.header-metric-value {
		padding-left: 8.75px; }

	.filters-container {
		margin-top: 6.25px;
		height: 50px; }

	.filters-container-actor-adoption {
		margin-top: -25px;
		height: 50px;
		border-radius: 6.25px; }

	.filter-container {
		margin-left: 12.5px; }

	.filter-circle {
		width: 15px;
		height: 15px; }

	.filter-text {
		padding-left: 6.25px; }

	.filter-selected {
		width: 5px;
		height: 5px; }

	.legends-container {
		margin-bottom: 28.75px;
		padding: 0 12.5px; }

	.legend-color {
		width: 18.75px;
		height: 6.25px; }

	.legend-color-point {
		width: 11.25px;
		height: 11.25px;
		border: 2.5px solid red; }

	.legend-text {
		padding-left: 8.75px;
		padding-right: 12.5px; } }

@media ( min-width: 1920px ) {
	.header-container {
		height: 93px;
		padding: 15px; }

	.header-container-actor-adoption {
		height: 93px;
		padding: 0px 15px 15px 15px; }

	.graph-container {
		padding: 15px 0; }

	.graph-container-actor-adoption {
		padding: 52.5px 0 15px 0; }

	.text-container {
		padding: 22.5px; }

	.sub-heading-container {
		padding-top: 7.5px; }

	.sub-heading {
		padding-right: 15px; }

	.header-metric {
		padding-left: 15px; }

	.header-metric-title {
		padding-left: 3px; }

	.header-metric-value {
		padding-left: 10.5px; }

	.filters-container {
		margin-top: 7.5px;
		height: 60px; }

	.filters-container-actor-adoption {
		margin-top: -30px;
		height: 60px;
		border-radius: 7.5px; }

	.filter-container {
		margin-left: 15px; }

	.filter-circle {
		width: 18px;
		height: 18px; }

	.filter-text {
		padding-left: 7.5px; }

	.filter-selected {
		width: 6px;
		height: 6px; }

	.legends-container {
		margin-bottom: 34.5px;
		padding: 0 15px; }

	.legend-color {
		width: 22.5px;
		height: 7.5px; }

	.legend-color-point {
		width: 13.5px;
		height: 13.5px;
		border: 3px solid red; }

	.legend-text {
		padding-left: 10.5px;
		padding-right: 15px; } }

@media ( min-width: 2560px ) {
	.header-container {
		height: 124px;
		padding: 20px; }

	.header-container-actor-adoption {
		padding: 0px 20px 20px 20px;
		height: 124px; }

	.graph-container {
		padding: 20px 0; }

	.graph-container-actor-adoption {
		padding: 70px 0 20px 0; }

	.text-container {
		padding: 30px; }

	.sub-heading-container {
		padding-top: 10px; }

	.sub-heading {
		padding-right: 20px; }

	.header-metric {
		padding-left: 20px; }

	.header-metric-title {
		padding-left: 4px; }

	.header-metric-value {
		padding-left: 14px; }

	.filters-container {
		margin-top: 10px;
		height: 80px; }

	.filters-container-actor-adoption {
		margin-top: -40px;
		height: 80px;
		border-radius: 10px; }

	.filter-container {
		margin-left: 20px; }

	.filter-circle {
		width: 24px;
		height: 24px; }

	.filter-text {
		padding-left: 10px; }

	.filter-selected {
		width: 8px;
		height: 8px; }

	.legends-container {
		margin-bottom: 46px;
		padding: 0 20px; }

	.legend-color {
		width: 30px;
		height: 10px; }

	.legend-color-point {
		width: 18px;
		height: 18px;
		border: 4 px solid red; }

	.legend-text {
		padding-left: 14px;
		padding-right: 20px; } }
