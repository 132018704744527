@import '~styles/mixins.sass';

.container {
	width: 100%;
	height: 100%;
	background: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: #FFF;
	margin: 0px 17%;
	font-family: 'Open Sans';
	box-sizing: border-box;
	position: relative;
	top: 0px;
	animation: riseTop 1s linear 1; }

.header-container {
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: flex;
	//justify-content: space-between
	box-sizing: border-box; }

.header-title {
	padding: 10px 30px;
	box-sizing: border-box;
	display: flex;
	align-items: center; }

.header-indicator {
	position: relative;
	top: -2px;
	width: 28px;
	height: 40px; }

.delay-days-container {
	padding: 10px 10px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between; }

.delay-days-dynamic {
	padding-left: 5px;
	display: flex;
	align-items: center; }

.delay-days-static {
	display: flex;
	align-items: center; }

.action-name-container {
	text-transform: uppercase;
	display: flex; }

.action-name {
	padding: 2px 30px;
	width: 70%;
	box-sizing: border-box; }

.header-days {
	display: flex;
	padding-left: 60px;
	box-sizing: border-box;
	width: 30%; }

.previous-day {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #7E8E9C; }

.current-day {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #14324B; }

.close-container {
	height: 42px;
	width: 42px;
	border-top-right-radius: 9px;
	border-bottom-left-radius: 10px;
	cursor: pointer;
	padding: 10px;
	background-color: #835AC5;
	box-sizing: border-box;
	position: absolute;
	right: -2px;
	top: -2px; }

.body-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box; }

.body-desc {
	width: 70%;
	padding: 5px 30px 15px;
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	box-sizing: border-box; }

.body-metrics {
	width: 30%;
	display: flex;
	box-sizing: border-box;
	padding: 15px 5px; }

.metric-bar {
	width: 16px;
	height: 72px; }

.initiative-value-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0px 5px 0px 10px; }

.initiative-value {
	color: #835AC5;
	display: flex;
	align-items: center;
	margin: 5px 0px; }

.initiative-diff {
	margin-right: auto;
	margin-left: 5px;
	padding: 2px 5px;
	font-family: 'Acherus Militant 1';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: #FFFFFF;
	border-radius: 11px; }

.initiative-name {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 8px;
	text-transform: uppercase;
	line-height: 11px;
	color: #14324B;
	opacity: 0.8; }

.right-arrow-flat {
	width: 15px;
	margin: auto 0px 10px; }

.footer-container {
	width: 100%;
	background-color: #FFF;
	margin: 5px 0px 15px; }

.actor-container {
	display: flex;
	align-items: center; }

.actors-container {
	display: flex;
	width: 122%; }

.actor-image {
	border-radius: 50%;
	width: 16px;
	height: 16px; }

.actor-name {
	font-size: 11px;
	padding: 0px 10px 0px 4px; }

.org-chart {
	display: flex;
	justify-content: space-around;
	width: 100%; }

.organization {
	display: flex;
	flex-direction: column; }

.org-name {
	font-weight: 600;
	font-size: 9px;
	line-height: 12px;
	display: flex;
	align-items: center;
	text-align: center;
	text-transform: uppercase;
	color: #14324B;
	margin: 0px auto; }

.org-actors {
	display: flex;
	flex-direction: column; }

.client-partner {
	display: flex;
	flex-direction: column;
	justify-content: space-between; }

.client {
	display: flex;
	flex-direction: column;
	justify-content: center; }

.partner {
	display: flex;
	flex-direction: column;
	justify-content: center; }


@keyframes riseTop {
	0% {
		top: 250px; }
	100% {
		top: 0px; } }

@media  (min-width: 1440px) {
	.container {
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px;
		margin: 0px 17%;
		top: 0px; }

	.header-container {
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px; }

	.header-title {
		padding: 11.25px 33.75px; }

	.header-indicator {
		top: -2.25px;
		width: 31.5px;
		height: 45px; }

	.delay-days-container {
		padding: 11.25px 11.25px; }

	.delay-days-dynamic {
		padding-left: 5.625px; }

	.action-name {
		padding: 2.25px 33.75px; }

	.header-days {
		padding-left: 67.5px; }

	.previous-day {
		font-size: 13.5px;
		line-height: 15px; }

	.current-day {
		font-size: 13.5px;
		line-height: 15px; }

	.close-container {
		height: 47.25px;
		width: 47.25px;
		border-top-right-radius: 10.125px;
		border-bottom-left-radius: 11.25px;
		padding: 11.25px;
		right: -2.25px;
		top: -2.25px; }

	.body-desc {
		padding: 5.625px 33.75px 16.875px;
		font-size: 15.75px;
		line-height: 24.75px; }

	.body-metrics {
		padding: 16.875px 5.625px; }

	.metric-bar {
		width: 18px;
		height: 81px; }

	.initiative-value-container {
		margin: 0px 5.625px 0px 11.25px; }

	.initiative-value {
		margin: 5.625px 0px; }

	.initiative-diff {
		margin-left: 5.625px;
		padding: 2.25px 5.625px;
		font-size: 13.5px;
		line-height: 13.5px;
		border-radius: 12.375px; }

	.initiative-name {
		font-size: 9px;
		line-height: 12.375px; }

	.right-arrow-flat {
		width: 16.875px;
		margin: auto 0px 11.25px; }

	.footer-container {
		margin: 5.625px 0px 16.875px; }

	.actor-image {
		width: 18px;
		height: 18px; }

	.actor-name {
		font-size: 12.375px;
		padding: 0px 11.25px 0px 4.5px; }

	.org-name {
		font-size: 10.125px;
		line-height: 13.5px;
		margin: 0px auto; }

	@keyframes riseTop {
		0% {
			top: 281.25px; }
		100% {
			top: 0px; } } }

@media  (min-width: 1600px) {
	.container {
		border-top-left-radius: 12.5px;
		border-top-right-radius: 12.5px;
		margin: 0px 17%;
		top: 0px; }

	.header-container {
		border-top-left-radius: 12.5px;
		border-top-right-radius: 12.5px; }

	.header-title {
		padding: 12.5px 37.5px; }

	.header-indicator {
		top: -2.5px;
		width: 35px;
		height: 50px; }

	.delay-days-container {
		padding: 12.5px 12.5px; }

	.delay-days-dynamic {
		padding-left: 6.25px; }

	.action-name {
		padding: 2.5px 37.5px; }

	.header-days {
		padding-left: 75px; }

	.previous-day {
		font-size: 15px;
		line-height: 20px; }

	.current-day {
		font-size: 15px;
		line-height: 20px; }

	.close-container {
		height: 52.5px;
		width: 52.5px;
		border-top-right-radius: 11.25px;
		border-bottom-left-radius: 12.5px;
		padding: 12.5px;
		right: -2.5px;
		top: -2.5px; }

	.body-desc {
		padding: 6.25px 30px 18.75px;
		font-size: 17.5px;
		line-height: 27.5px; }

	.body-metrics {
		padding: 18.75px 6.25px; }

	.metric-bar {
		width: 20px;
		height: 90px; }

	.initiative-value-container {
		margin: 0px 6.25px 0px 12.5px; }

	.initiative-value {
		margin: 6.25px 0px; }

	.initiative-diff {
		margin-left: 6.25px;
		padding: 2.5px 6.25px;
		font-size: 15px;
		line-height: 15px;
		border-radius: 13.75px; }

	.initiative-name {
		font-size: 10px;
		line-height: 13.75px; }

	.right-arrow-flat {
		width: 18.75px;
		margin: auto 0px 12.5px; }

	.footer-container {
		margin: 6.25px 0px 18.75px; }

	.actor-image {
		width: 20px;
		height: 20px; }

	.actor-name {
		font-size: 13.75px;
		padding: 0px 12.5px 0px 5px; }

	.org-name {
		font-size: 11.25px;
		line-height: 15px;
		margin: 0px auto; }


	@keyframes riseTop {
		0% {
			top: 312.5px; }
		100% {
			top: 0px; } } }

@media  (min-width: 1920px) {
	.container {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		margin: 0px 17%;
		top: 0px; }

	.header-container {
		border-top-left-radius: 15px;
		border-top-right-radius: 15px; }

	.header-title {
		padding: 15px 45px; }

	.header-indicator {
		top: -3px;
		width: 42px;
		height: 60px; }

	.delay-days-container {
		padding: 15px 15px; }

	.delay-days-dynamic {
		padding-left: 7.5px; }

	.action-name {
		padding: 3px 45px; }

	.header-days {
		padding-left: 90px; }

	.previous-day {
		font-size: 18px;
		line-height: 20px; }

	.current-day {
		font-size: 18px;
		line-height: 20px; }

	.close-container {
		height: 63px;
		width: 63px;
		border-top-right-radius: 13.5px;
		border-bottom-left-radius: 15px;
		padding: 15px;
		right: -3px;
		top: -3px; }

	.body-desc {
		padding: 7.5px 45px 22.5px;
		font-size: 21px;
		line-height: 33px; }

	.body-metrics {
		padding: 22.5px 7.5px; }

	.metric-bar {
		width: 24px;
		height: 108px; }

	.initiative-value-container {
		margin: 0px 7.5px 0px 15px; }

	.initiative-value {
		margin: 7.5px 0px; }

	.initiative-diff {
		margin-left: 7.5px;
		padding: 3px 7.5px;
		font-size: 18px;
		line-height: 18px;
		border-radius: 16.5px; }

	.initiative-name {
		font-size: 12px;
		line-height: 16.5px; }

	.right-arrow-flat {
		width: 22.5px;
		margin: auto 0px 15px; }

	.footer-container {
		margin: 7.5px 0px 22.5px; }

	.actor-image {
		width: 24px;
		height: 24px; }

	.actor-name {
		font-size: 16.5px;
		padding: 0px 15px 0px 6px; }

	.org-name {
		font-size: 13.5px;
		line-height: 18px;
		margin: 0px auto; }

	@keyframes riseTop {
		0% {
			top: 375px; }
		100% {
			top: 0px; } } }

@media  (min-width: 2560px) {
	.container {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		margin: 0px 17%;
		top: 0px; }

	.header-container {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px; }

	.header-title {
		padding: 20px 60px; }

	.header-indicator {
		top: -4px;
		width: 56px;
		height: 80px; }

	.delay-days-container {
		padding: 20px 20px; }

	.delay-days-dynamic {
		padding-left: 10px; }

	.action-name {
		padding: 4px 60px; }

	.header-days {
		padding-left: 120px; }

	.previous-day {
		font-size: 24px;
		line-height: 32px; }

	.current-day {
		font-size: 24px;
		line-height: 32px; }

	.close-container {
		height: 84px;
		width: 84px;
		border-top-right-radius: 18px;
		border-bottom-left-radius: 20px;
		padding: 20px;
		right: -4px;
		top: -4px; }

	.body-desc {
		padding: 10px 48px 60px;
		font-size: 28px;
		line-height: 44px; }

	.body-metrics {
		padding: 30px 10px; }

	.metric-bar {
		width: 32px;
		height: 144px; }

	.initiative-value-container {
		margin: 0px 10px 0px 20px; }

	.initiative-value {
		margin: 10px 0px; }

	.initiative-diff {
		margin-left: 10px;
		padding: 4px 10px;
		font-size: 24px;
		line-height: 24px;
		border-radius: 22px; }

	.initiative-name {
		font-size: 16px;
		line-height: 22x; }

	.right-arrow-flat {
		width: 30px;
		margin: auto 0px 20px; }

	.footer-container {
		margin: 10px 0px 30px; }

	.actor-image {
		width: 32px;
		height: 32px; }

	.actor-name {
		font-size: 22px;
		padding: 0px 20px 0px 8px; }

	.org-name {
		font-size: 18px;
		line-height: 24px;
		margin: 0px auto; }


	@keyframes riseTop {
		0% {
			top: 500px; }
		100% {
			top: 0px; } } }
