@import '~styles/mixins.sass';

.report-card-container {
	margin: 15.4px 15.4px 15.4px 15.4px;
	width: 387px;
	height: 243px;
	box-sizing: border-box;
	padding: 0px 7px 5px 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-radius: 4px; }

.title {
	width: 100%;
	// height: 30px
	text-align: center;
	box-sizing: border-box;
	margin-top: 10px;
	@include display-flex-center-mixin; }

.title-line {
	width: 65%;
	height: 1px;
	margin-top: 4px;
	margin-bottom: 5px; }

.content {
	width: 100%;
	height: calc(100% - 32px);
	display: flex;
	//justify-content: center
	align-items: center;
	flex-direction: column; }

.point {
	width: 100%;
	box-sizing: border-box;
	padding: 6px 0px 2px 25px;
	position: relative; }

@media ( min-width: 1440px ) {
	.report-card-container {
		margin: 17.325px 17.325px 17.325px 17.325px;
		width: 435.375px;
		height: 273.375px;
		padding: 0px 7.875px 5.626px 7.875px;
		border-radius: 4.5px; }

	.title {
		margin-top: 11.25px; }

	.title-line {
		height: 1.125px;
		margin-top: 4.5px;
		margin-bottom: 5.625px; }

	.content {
		height: calc(100% - 36px); }

	.point {
		padding: 6.75px 0px 2.25px 28.125px; } }

@media ( min-width: 1600px ) {
	.report-card-container {
		margin: 30.8px 30.8px 30.8px 30.8px;
		width: 483.75px;
		height: 303.75px;
		padding: 0px 8.75px 6.25px 8.75px;
		border-radius: 5px; }

	.title {
		margin-top: 12.5px; }

	.title-line {
		height: 1.25px;
		margin-top: 5px;
		margin-bottom: 6.25px; }

	.content {
		height: calc(100% - 40px); }

	.point {
		padding: 7.5px 0px 2.5px 31.25px; } }


@media ( min-width: 1920px ) {
	.report-card-container {
		margin: 23.1px 23.1px 23.1px 23.1px;
		width: 580.5px;
		height: 364.5px;
		padding: 0px 10.5px 7.5px 10.5px;
		border-radius: 6px; }

	.title {
		margin-top: 15px; }

	.title-line {
		height: 1.5px;
		margin-top: 6px;
		margin-bottom: 7.5px; }

	.content {
		height: calc(100% - 48px); }

	.point {
		padding: 9px 0px 3px 37.5px; } }

@media ( min-width: 2560px ) {
	.report-card-container {
		margin: 30.8px 30.8px 30.8px 30.8px;
		width: 774px;
		height: 486px;
		padding: 0px 14px 10px 14px;
		border-radius: 8px; }

	.title {
		margin-top: 20px; }

	.title-line {
		height: 2px;
		margin-top: 8px;
		margin-bottom: 10px; }

	.content {
		height: calc(100% - 64px); }

	.point {
		padding: 12px 0px 4px 50px; } }
