@import '~styles/mixins.sass';

.container {
	width: calc(100% - 12px);
	padding: 5px 0;
	margin-right: 100px;
	border-radius: 6px;
	box-sizing: border-box; }

.header {
	width: 100%;
	min-height: 34px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 4px 10px; }

.image {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center; }

.text {
	padding-left: 7px;
	line-height: 1.2; }

.description {
	width: 100%;
	text-align: left;
	padding: 10px 10px 5px 10px;
	box-sizing: border-box; }

.day-container {
	width: 100%;
	padding: 2px 10px 10px 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box; }

.day-image {
	width: 15px;
	height: 16px; }

.day-text {
	padding-left: 4px; }

.actors-container {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap; }

.footer {
	width: 100%;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	box-sizing: border-box;
	padding: 0 10px; }

.cancel {
	width: 60px;
	@include display-flex-center-mixin; }

.proceed {
	width: 110px;
	height: 32px;
	@include display-flex-center-mixin;
	border-radius: 6px; }

.option-container {
	width: 100%;
	margin-top: 5px; }

@media ( min-width: 1440px ) {
	.container {
		width: calc(100% - 13.5px);
		padding: 5.625px 0;
		border-radius: 6.75px;
		margin-left: 13.5px; }

	.header {
		min-height: 38.25px;
		padding: 4.5px 11.25px; }

	.image {
		width: 22.5px;
		height: 22.5px; }

	.text {
		padding-left: 7.875px; }

	.description {
		padding: 11.25px 11.25px 5.625px 11.25px; }

	.day-container {
		padding: 2.25px 11.25px 11.25px 11.25px; }

	.day-image {
		width: 16.875px;
		height: 18px; }

	.day-text {
		padding-left: 4.5px; }

	.actors-container {
		padding: 11.25px; }

	.footer {
		height: 61.875px;
		padding: 0 11.25px; }

	.cancel {
		width: 67.5px; }

	.proceed {
		width: 123.75px;
		height: 36px;
		border-radius: 6.75px; }

	.option-container {
		margin-top: 5.625px; } }

@media ( min-width: 1600px ) {
	.container {
		width: calc(100% - 15px);
		padding: 6.25px 0;
		border-radius: 7.5px;
		margin-left: 15px; }

	.header {
		min-height: 42.5px;
		padding: 5px 12.5px; }

	.image {
		width: 25px;
		height: 25px; }

	.text {
		padding-left: 8.75px; }

	.description {
		padding: 12.5px 12.5px 6.25px 12.5px; }

	.day-container {
		padding: 2.5px 12.5px 12.5px 12.5px; }

	.day-image {
		width: 18.75px;
		height: 20px; }

	.day-text {
		padding-left: 5px; }

	.actors-container {
		padding: 12.5px; }

	.footer {
		height: 68.75px;
		padding: 0 12.5px; }

	.cancel {
		width: 75px; }

	.proceed {
		width: 137.5px;
		height: 40px;
		border-radius: 7.5px; }

	.option-container {
		margin-top: 6.25px; } }


@media ( min-width: 1920px ) {
	.container {
		width: calc(100% - 18px);
		padding: 7.5px 0;
		border-radius: 9px;
		margin-left: 18px; }

	.header {
		min-height: 51px;
		padding: 6px 15px; }

	.image {
		width: 30px;
		height: 30px; }

	.text {
		padding-left: 10.5px; }

	.description {
		padding: 15px 15px 7.5px 15px; }

	.day-container {
		padding: 3px 15px 15px 15px; }

	.day-image {
		width: 22.5px;
		height: 24px; }

	.day-text {
		padding-left: 6px; }

	.actors-container {
		padding: 15px; }

	.footer {
		height: 82.5px;
		padding: 0 15px; }

	.cancel {
		width: 90px; }

	.proceed {
		width: 165px;
		height: 48px;
		border-radius: 9px; }

	.option-container {
		margin-top: 7.5px; } }

@media ( min-width: 2560px ) {
	.container {
		width: calc(100% - 24px);
		padding: 10px 0;
		border-radius: 12px;
		margin-left: 24px; }

	.header {
		min-height: 68px;
		padding: 8px 20px; }

	.image {
		width: 40px;
		height: 40px; }

	.text {
		padding-left: 14px; }

	.description {
		padding: 20px 20px 10px 20px; }

	.day-container {
		padding: 4px 20px 20px 20px; }

	.day-image {
		width: 30px;
		height: 32px; }

	.day-text {
		padding-left: 8px; }

	.actors-container {
		padding: 20px; }

	.footer {
		height: 110px;
		padding: 0 20px; }

	.cancel {
		width: 87px; }

	.proceed {
		width: 220px;
		height: 64px;
		border-radius: 12px; }

	.option-container {
		margin-top: 10px; } }
