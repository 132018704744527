@import '~styles/mixins.sass';

.separation {
	width: 2px;
	height: 24px; }

@media ( min-width: 1440px ) {
		width: 2.25px;
		height: 27px; }

@media ( min-width: 1600px ) {
	.separation {
		width: 2.5px;
		height: 30px; } }

@media ( min-width: 1920px ) {
	.separation {
		width: 3px;
		height: 36px; } }

@media ( min-width: 2560px ) {
	.separation {
		width: 4px;
		height: 48px; } }
