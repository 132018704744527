@import '~styles/mixins.sass';

.objectives-container {
	width: 1020px;
	height: 570px;
	overflow: hidden;
	border-radius: 5px; }

.header-container {
	width: 100%;
	height: 44px;
	display: flex; }

.header-line {
	width: calc(100% - 8px);
	height: 1px;
	margin: 0 4px; }

.header-content {
	width: calc(100% - 44px);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 0 10px; }

.header-close {
	width: 44px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 5px;
	cursor: pointer; }

.content-container {
	width: 100%;
	height: calc(100% - 45px);
	@include display-flex-center-mixin; }

.left-container {
	width: 60%;
	height: 100%;
	box-sizing: border-box;
	padding: 10px 20px 10px 40px; }

.events-container {
	width: 100%;
	height: 100%; }

.right-container {
	width: 40%;
	height: 100%;
	@include display-flex-center-mixin;
	flex-direction: column; }

.close-image {
	width: 17px;
	height: 18px; }

.intro-image-container {
	width: 100%;
	box-sizing: border-box;
	padding: 15px 0; }

.intro-image {
	width: 400px;
	height: 170px; }

.cdo-intro-content-div {
	height: 50%;
	width: 300px;
	margin-top: 20px; }

.cdo-intro-content {
	width: 300px;
	position: relative;
	z-index: 3;
	border-radius: 12px; }

.cdo-intro-content-border {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 1px solid #835AC5;
	top: -8px;
	left: -8px;
	border-radius: 12px; }

.cdo-intro-content-list {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding: 10px;
	border-radius: 12px;
	box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.1); }

.objective-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	box-sizing: border-box;
	padding: 5px 10px; }

.objective-heading {
	width: 100%;
	padding: 5px 10px;
	box-sizing: border-box; }

.objectives-list {
	width: 100%; }

.objective-point {
	width: 20px;
	margin-top: 2px;
	display: flex;
	align-items: center;
	justify-self: center; }

.objective-text {
	width: calc(100% - 20px);
	box-sizing: border-box;
	padding-left: 5px; }

.intro-user-role-divs {
	box-sizing: border-box;
	width: 100%;
	padding: 15px; }

.user-role-main {
	padding: 0px; }

.user-role-sub {
	padding: 0 5px; }

.events-container {}


.intro-events-content {
	position: absolute;
	width: 100%;
	height: calc(100% - 50px);
	max-height: 550px;
	box-sizing: border-box;
	margin-bottom: 50px;
	z-index: 2;
	// top: 0
	left: 0;
	padding-left: 120px;
	padding-right: 100px;
	padding-top: 20px;
	overflow: hidden; }

.intro-event-container {
	width: 100%;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 30px 10px 25px;
	box-sizing: border-box;
	position: relative; }

.intro-event-image {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid gray; }

.intro-event-content {
	width: calc(100% - 64px);
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding-left: 15px;
	justify-content: center; }

.intro-event-heading {
	width: 100%;
	padding-bottom: 5px; }

.intro-event-desc {
	width: 100%; }

.intro-event-line {
	width: 2px;
	height: 90px;
	position: absolute;
	top: -40px;
	left: 0; }

.intro-event-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	left: -4px;
	top: calc(50% - 5px); }

@media ( min-width: 1440px ) {
	.objectives-container {
		width: 1147.5px;
		height: 641.25px;
		border-radius: 5.625px; }

	.header-container {
		height: 49.5px; }

	.header-line {
		width: calc(100% - 9px);
		height: 1.125px;
		margin: 0 4.5px; }

	.header-content {
		width: calc(100% - 49.5px);
		padding: 0 11.25px; }

	.header-close {
		width: 49.5px;
		border-bottom-left-radius: 5.625px; }

	.content-container {
		height: calc(100% - 50.625px); }

	.left-container {
		padding: 11.25px 22.5px 11.25px 45px; }

	.close-image {
		width: 19.125px;
		height: 20.25px; }

	.intro-image-container {
		padding: 16.875px 0; }

	.intro-image {
		width: 450px;
		height: 191.25px; } }

@media ( min-width: 1600px ) {
	.objectives-container {
		width: 1275px;
		height: 712px;
		border-radius: 6.25px; }

	.header-container {
		height: 55px; }

	.header-line {
		width: calc(100% - 10px);
		height: 1.25px;
		margin: 0 5px; }

	.header-content {
		width: calc(100% - 55px);
		padding: 0 12.5px; }

	.header-close {
		width: 55px;
		border-bottom-left-radius: 6.25px; }

	.content-container {
		height: calc(100% - 56.25px); }

	.left-container {
		padding: 12.5px 25px 12.5px 50px; }

	.close-image {
		width: 21.25px;
		height: 22.25px; }

	.intro-image-container {
		padding: 18.75px 0; }

	.intro-image {
		width: 500px;
		height: 212.5px; }

	.cdo-intro-content-div {
		width: 375px;
		margin-top: 25px; }

	.cdo-intro-content {
		width: 375px;
		border-radius: 15px; }

	.cdo-intro-content-border {
		border: 1.25px solid #835AC5;
		top: -10px;
		left: -10px;
		border-radius: 15px; }

	.cdo-intro-content-list {
		padding: 12.5px;
		border-radius: 15px;
		box-shadow: 8.75px 8.75px 25px rgba(0, 0, 0, 0.1); }

	.objective-container {
		padding: 6.25px 12.5px; }

	.objective-heading {
		padding: 6.25px 12.5px; }

	.objective-point {
		width: 25px;
		margin-top: 2.5px; }

	.objective-text {
		width: calc(100% - 25px);
		padding-left: 6.25px; }

	.intro-user-role-divs {
		padding: 18.75px; }

	.user-role-sub {
		padding: 0 6.25px; }

	.intro-events-content {
		height: calc(100% - 62.5px);
		max-height: 687.5px;
		margin-bottom: 62.5px;
		left: 0;
		padding-left: 150px;
		padding-right: 125px;
		padding-top: 25px; }

	.intro-event-container {
		height: 112.5px;
		padding: 12.5px 37.5px 12.5px 31.25px; }

	.intro-event-image {
		width: 80px;
		height: 80px;
		border: 1.25px solid gray; }

	.intro-event-content {
		width: calc(100% - 80px);
		padding-left: 18.75px; }

	.intro-event-heading {
		padding-bottom: 6.25px; }

	.intro-event-line {
		width: 2.5px;
		height: 112.5px;
		top: -50px;
		left: 0; }

	.intro-event-dot {
		width: 12.5px;
		height: 12.5px;
		left: -5px;
		top: calc(50% - 6.25px); } }

@media ( min-width: 1920px ) {
	.objectives-container {
		width: 1530px;
		height: 855px;
		border-radius: 7.5px; }

	.header-container {
		height: 66px; }

	.header-line {
		width: calc(100% - 12px);
		height: 1.5px;
		margin: 0 6px; }

	.header-content {
		width: calc(100% - 66px);
		padding: 0 15px; }

	.header-close {
		width: 66px;
		border-bottom-left-radius: 7.5px; }

	.content-container {
		height: calc(100% - 67.5px); }

	.left-container {
		padding: 15px 30px 15px 60px; }

	.close-image {
		width: 25.5px;
		height: 27px; }

	.intro-image-container {
		padding: 22.5px 0; }

	.intro-image {
		width: 600px;
		height: 255px; }

	.cdo-intro-content-div {
		width: 450px;
		margin-top: 30px; }

	.cdo-intro-content {
		width: 450px;
		border-radius: 18px; }

	.cdo-intro-content-border {
		border: 1.5px solid #835AC5;
		top: -12px;
		left: -12px;
		border-radius: 18px; }

	.cdo-intro-content-list {
		padding: 15px;
		border-radius: 18px;
		box-shadow: 10.5px 10.5px 25px rgba(0, 0, 0, 0.1); }

	.objective-container {
		padding: 7.5px 15px; }

	.objective-heading {
		padding: 7.5px 15px; }

	.objective-point {
		width: 30px;
		margin-top: 3px; }

	.objective-text {
		width: calc(100% - 30px);
		padding-left: 7.5px; }

	.intro-user-role-divs {
		padding: 22.5px; }

	.user-role-sub {
		padding: 0 7.5px; }

	.intro-events-content {
		height: calc(100% - 75px);
		max-height: 825px;
		margin-bottom: 112.5px;
		padding-left: 180px;
		padding-right: 150px;
		padding-top: 30px; }

	.intro-event-container {
		height: 135px;
		padding: 15px 45px 15px 37.5px; }

	.intro-event-image {
		width: 96px;
		height: 96px;
		border: 1.5px solid gray; }

	.intro-event-content {
		width: calc(100% - 96px);
		padding-left: 22.5px; }

	.intro-event-heading {
		padding-bottom: 7.5px; }

	.intro-event-line {
		width: 3px;
		height: 135px;
		top: -60px;
		left: 0; }

	.intro-event-dot {
		width: 15px;
		height: 15px;
		left: -6px; } }

@media ( min-width: 2560px ) {
	.objectives-container {
		width: 2040px;
		height: 1139.2px;
		border-radius: 10px; }

	.header-container {
		height: 88px; }

	.header-line {
		width: calc(100% - 16px);
		height: 2px;
		margin: 0 8px; }

	.header-content {
		width: calc(100% - 88px);
		padding: 0 20px; }

	.header-close {
		width: 88px;
		border-bottom-left-radius: 10px; }

	.content-container {
		height: calc(100% - 90px); }

	.left-container {
		padding: 20px 40px 20px 80px; }

	.close-image {
		width: 34px;
		height: 36px; }

	.intro-image-container {
		padding: 30px 0; }

	.intro-image {
		width: 800px;
		height: 340px; }

	.cdo-intro-content-div {
		width: 600px;
		margin-top: 40px; }

	.cdo-intro-content {
		width: 600px;
		border-radius: 24px; }

	.cdo-intro-content-border {
		border: 2px solid #835AC5;
		top: -16px;
		left: -16px;
		border-radius: 24px; }

	.cdo-intro-content-list {
		padding: 20px;
		border-radius: 24px;
		box-shadow: 14px 14px 40px rgba(0, 0, 0, 0.1); }

	.objective-container {
		padding: 10px 20px; }

	.objective-heading {
		padding: 10px 20px; }

	.objective-point {
		width: 40px;
		margin-top: 4px; }

	.objective-text {
		width: calc(100% - 40px);
		padding-left: 10px; }

	.intro-user-role-divs {
		padding: 30px; }

	.user-role-sub {
		padding: 0 10px; }

	.intro-events-content {
		height: calc(100% - 100px);
		max-height: 1100px;
		margin-bottom: 100px;
		left: 0;
		padding-left: 240px;
		padding-right: 200px;
		padding-top: 40px; }

	.intro-event-container {
		height: 180px;
		padding: 20px 60px 20px 50px; }

	.intro-event-image {
		width: 128px;
		height: 128px;
		border: 2px solid gray; }

	.intro-event-content {
		width: calc(100% - 128px);
		padding-left: 30px; }

	.intro-event-heading {
		padding-bottom: 10px; }

	.intro-event-line {
		width: 4px;
		height: 180px;
		top: -80px;
		left: 0; }

	.intro-event-dot {
		width: 20px;
		height: 20px;
		left: -8px;
		top: calc(50% - 10px); } }
