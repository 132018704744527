@import '~styles/mixins.sass';

.container {
	width: 640px;
	height: 360px;
	overflow: hidden;
	border-radius: 5px;
	position: relative;
	background-color: #FFF;
	border-radius: 10px; }

.close-container {
	height: 42px;
	width: 42px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	cursor: pointer;
	padding: 10px;
	background-color: #835AC5;
	box-sizing: border-box;
	position: absolute;
	right: -1px;
	top: -1px;
	z-index: 1; }

.actor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20%;
    width: 100%; }

.each-actor-container {
	display: block;
	width: 150px; }

.actor-image-container {
    display: flex;
    justify-content: center;
    margin-left: 35px;
    margin-bottom: 10px; }

.more-indicator-container {
	background-color: #1BE1D4;
	border-radius: 20px;
	width: 80px;
	height: 40px;
	text-align: center;
	color: #FFF;
	margin-left: 25px; }

.more-indicator-number {
	font-size: 18px;
	font-weight: 600; }

.more-indicator-text {
	font-size: 16px; }

.actor {
    width: 70px;
    height: 70px;
    border-radius: 50%; }

.actor-tick {
    width: 40px;
    height: 40px;
    position: relative;
    top: 30px;
    border-radius: 50%;
    overflow: hidden;
    left: -20px; }


.confetti-container {
	position: absolute;
	top: 0px;
	height: 40%;
	width: 100%;
	display: flex;
	justify-content: center; }

.content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 55%;
	width: 100%; }

.title {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 27px;
	text-align: center;
	color: #14324B;
	margin: 10px;
	margin-top: 30px; }

.title-name {
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	text-align: center; }

.title-designation {
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	text-align: center;
	color: #7E8E9C; }

.description {
	width: 80%;
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 164.5%;
	text-align: center;
	color: #14324B;
	opacity: 0.8; }

.button {
	cursor: pointer;
	background: #835AC5;
	opacity: 0.9;
	border: 1px solid #835AC5;
	border-radius: 4px;
	width: 220px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px; }

.button-text {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #F7F7F9;
	border-radius: 35px; }

@media ( min-width: 1440px ) {
	.container {
		width: 720px;
		height: 405px;
		border-radius: 5.625px;
		border-radius: 11.25px; }

	.close-container {
		height: 47.25px;
		width: 47.25px;
		border-top-right-radius: 11.25px;
		border-bottom-left-radius: 11.25px;
		padding: 11.25px;
		right: -1.125px;
		top: -1.125px; }

	.each-actor-container {
		width: 168.75px; }

	.actor-image-container {
		margin-left: 39.375px;
		margin-bottom: 11.25px; }

	.more-indicator-container {
		border-radius: 22.5px;
		width: 90px;
		height: 45px;
		margin-left: 28.125px; }

	.more-indicator-number {
		font-size: 20.25px; }

	.more-indicator-text {
		font-size: 18px; }

	.actor {
		width: 78.75px;
		height: 78.75px; }

	.actor-tick {
		width: 45px;
		height: 45px;
		top: 33.75px;
		left: -22.5px; }

	.confetti-container {
		top: 0px; }

	.title {
		font-size: 22.5px;
		line-height: 30.375px;
		margin: 11.25px;
		margin-top: 33.75px; }

	.title-name {
		font-size: 15.75px;
		line-height: 21.375px; }

	.title-designation {
		font-size: 11.25px;
		line-height: 15.75px; }

	.description {
		font-size: 14.625px; }

	.button {
		border-radius: 4.5px;
		width: 247.5px;
		height: 47.25px;
		margin: 11.25px; }

	.button-text {
		font-size: 18px;
		line-height: 24.75px;
		border-radius: 39.375px; } }

@media ( min-width: 1600px ) {
	.container {
		width: 800px;
		height: 450px;
		border-radius: 6.25px;
		border-radius: 12.5px; }

	.close-container {
		height: 52.5px;
		width: 52.5px;
		border-top-right-radius: 12.5px;
		border-bottom-left-radius: 12.5px;
		padding: 12.5px;
		right: -1.25px;
		top: -1.25px; }

	.each-actor-container {
		width: 187.5px; }

	.actor-image-container {
		margin-left: 43.75px;
		margin-bottom: 12.5px; }

	.more-indicator-container {
		border-radius: 25px;
		width: 100px;
		height: 50px;
		margin-left: 31.25px; }

	.more-indicator-number {
		font-size: 22.5px; }

	.more-indicator-text {
		font-size: 20px; }

	.actor {
		width: 87.5px;
		height: 87.5px; }

	.actor-tick {
		width: 50px;
		height: 50px;
		top: 37.5px;
		left: -25px; }

	.confetti-container {
		top: 0px; }

	.title {
		font-size: 25px;
		line-height: 33.75px;
		margin: 12.5px;
		margin-top: 37.5px; }

	.title-name {
		font-size: 17.5px;
		line-height: 23.75px; }

	.title-designation {
		font-size: 12.5px;
		line-height: 17.5px; }

	.description {
		font-size: 16.25px; }

	.button {
		border-radius: 5px;
		width: 275px;
		height: 52.5px;
		margin: 12.5px; }

	.button-text {
		font-size: 20px;
		line-height: 27.5px;
		border-radius: 43.75px; } }

@media ( min-width: 1920px ) {
	.container {
		width: 960px;
		height: 540px;
		border-radius: 7.5px;
		border-radius: 15px; }

	.close-container {
		height: 63px;
		width: 63px;
		border-top-right-radius: 15px;
		border-bottom-left-radius: 15px;
		padding: 15px;
		right: -1.5px;
		top: -1.5px; }

	.each-actor-container {
		width: 225px; }

	.actor-image-container {
		margin-left: 52.5px;
		margin-bottom: 15px; }

	.more-indicator-container {
		border-radius: 30px;
		width: 120px;
		height: 60px;
		margin-left: 37.5px; }

	.more-indicator-number {
		font-size: 27px; }

	.more-indicator-text {
		font-size: 24px; }

	.actor {
		width: 105px;
		height: 105px; }

	.actor-tick {
		width: 60px;
		height: 60px;
		top: 45px;
		left: -30px; }

	.confetti-container {
		top: 0px; }

	.title {
		font-size: 30px;
		line-height: 40.5px;
		margin: 15px;
		margin-top: 45px; }

	.title-name {
		font-size: 21px;
		line-height: 28.5px; }

	.title-designation {
		font-size: 15px;
		line-height: 21px; }

	.description {
		font-size: 19.5px; }

	.button {
		border-radius: 6px;
		width: 330px;
		height: 63px;
		margin: 15px; }

	.button-text {
		font-size: 24px;
		line-height: 33px;
		border-radius: 52.5px; } }

@media ( min-width: 2560px ) {
	.container {
		width: 1280px;
		height: 720px;
		border-radius: 10px;
		border-radius: 20px; }

	.close-container {
		height: 84px;
		width: 84px;
		border-top-right-radius: 20px;
		border-bottom-left-radius: 20px;
		padding: 20px;
		right: -2px;
		top: -2px; }

	.each-actor-container {
		width: 300px; }

	.actor-image-container {
		margin-left: 70px;
		margin-bottom: 20px; }

	.more-indicator-container {
		border-radius: 40px;
		width: 160px;
		height: 80px;
		margin-left: 50px; }

	.more-indicator-number {
		font-size: 36px; }

	.more-indicator-text {
		font-size: 32px; }

	.actor {
		width: 140px;
		height: 140px; }

	.actor-tick {
		width: 80px;
		height: 80px;
		top: 60px;
		left: -40px; }

	.confetti-container {
		top: 0px; }

	.title {
		font-size: 40px;
		line-height: 54px;
		margin: 20px;
		margin-top: 60px; }

	.title-name {
		font-size: 28px;
		line-height: 38px; }

	.title-designation {
		font-size: 20px;
		line-height: 28px; }

	.description {
		font-size: 26px; }

	.button {
		border-radius: 8px;
		width: 440px;
		height: 84px;
		margin: 20px; }

	.button-text {
		font-size: 32px;
		line-height: 44px;
		border-radius: 70px; } }
