@import '~styles/mixins.sass';

.container {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	// overflow: hidden
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative; }

.progress {
	width: 100%;
	border-radius: 3px; }

.change {
	position: absolute;
	width: 36px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	bottom: -10px;
	left: calc(50% - 18px); }

.change-animation-positive {
	animation-name: animateChangePositive;
	animation-timing-function: linear;
	animation-duration: 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards; }

@keyframes animateChangePositive {
	0% {
		opacity: 0;
		bottom: -10px; }
	33% {
		opacity: 1;
		bottom: 10px; }
	66% {
		opacity: 1;
		bottom: 30px; }
	100% {
		opacity: 1;
		bottom: 60px; } }


.change-animation-negative {
	animation-name: animateChangeNegative;
	animation-timing-function: linear;
	animation-duration: 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards; }

@keyframes animateChangeNegative {
	0% {
		opacity: 1;
		bottom: 60px; }
	33% {
		opacity: 1;
		bottom: 30px; }
	66% {
		opacity: 1;
		bottom: 10px; }
	100% {
		opacity: 0;
		bottom: -10px; } }

.target-text {
	font-size: 10px;
	padding-left: 4px; }

@media ( min-width: 1440px ) {
	.container {
		border-radius: 3.375px; }
	.progress {
		border-radius: 3.375px; }

	.change {
		width: 40.5px;
		height: 22.5px;
		border-radius: 13.5px;
		bottom: -11.25px;
		left: calc(50% - 20.25px); }

	@keyframes animateChangePositive {
		0% {
			opacity: 0;
			bottom: -11.25px; }
		33% {
			opacity: 1;
			bottom: 11.25px; }
		66% {
			opacity: 1;
			bottom: 33.75px; }
		100% {
			opacity: 1;
			bottom: 67.5px; } }

	@keyframes animateChangeNegative {
		0% {
			opacity: 1;
			bottom: 67.5px; }
		33% {
			opacity: 1;
			bottom: 33.75px; }
		66% {
			opacity: 1;
			bottom: 11.25px; }
		100% {
			opacity: 0;
			bottom: -11.25px; } }

	.target-text {
		font-size: 11.25px;
		padding-left: 4.5px; } }

@media ( min-width: 1600px ) {
	.container {
		border-radius: 5px; }

	.progress {
		border-radius: 3.75px; }

	.change {
		width: 45px;
		height: 25px;
		border-radius: 15px;
		bottom: -12.5px;
		left: calc(50% - 22.5px); }

	@keyframes animateChangePositive {
		0% {
			opacity: 0;
			bottom: -12.5px; }
		33% {
			opacity: 1;
			bottom: 12.5px; }
		66% {
			opacity: 1;
			bottom: 37.5px; }
		100% {
			opacity: 1;
			bottom: 75px; } }

	@keyframes animateChangeNegative {
		0% {
			opacity: 1;
			bottom: 75px; }
		33% {
			opacity: 1;
			bottom: 37.5px; }
		66% {
			opacity: 1;
			bottom: 12.5px; }
		100% {
			opacity: 0;
			bottom: -12.5px; } }

	.target-text {
		font-size: 12px;
		padding-left: 5px; } }

@media ( min-width: 1920px ) {
	.container {
		border-radius: 6px; }
	.progress {
		border-radius: 4.5px; }

	.change {
		width: 54px;
		height: 30px;
		border-radius: 18px;
		bottom: -15px;
		left: calc(50% - 27px); }

	@keyframes animateChangePositive {
		0% {
			opacity: 0;
			bottom: -15px; }
		33% {
			opacity: 1;
			bottom: 15px; }
		66% {
			opacity: 1;
			bottom: 45px; }
		100% {
			opacity: 1;
			bottom: 90px; } }

	@keyframes animateChangeNegative {
		0% {
			opacity: 1;
			bottom: 90px; }
		33% {
			opacity: 1;
			bottom: 45px; }
		66% {
			opacity: 1;
			bottom: 15px; }
		100% {
			opacity: 0;
			bottom: -15px; } }

	.target-text {
		font-size: 15px;
		padding-left: 6px; } }

@media ( min-width: 2560px ) {
	.container {
		border-radius: 8px; }

	.progress {
		border-radius: 6px; }

	.change {
		width: 72px;
		height: 40px;
		border-radius: 24px;
		bottom: -20px;
		left: calc(50% - 36px); }

	@keyframes animateChangePositive {
		0% {
			opacity: 0;
			bottom: -20px; }
		33% {
			opacity: 1;
			bottom: 20px; }
		66% {
			opacity: 1;
			bottom: 60px; }
		100% {
			opacity: 1;
			bottom: 120px; } }

	@keyframes animateChangeNegative {
		0% {
			opacity: 1;
			bottom: 120px; }
		33% {
			opacity: 1;
			bottom: 60px; }
		66% {
			opacity: 1;
			bottom: 20px; }
		100% {
			opacity: 0;
			bottom: -20px; } }

	.target-text {
		font-size: 19.2px;
		padding-left: 8px; } }
