@import '~styles/mixins.sass';

.reportPopup-container {
	width: 1000px;
	height: 540px;
	overflow: hidden;
	border-radius: 5px; }

.header-container {
	width: 100%;
	height: 44px;
	display: flex; }

.header-line {
	width: calc(100% - 8px);
	height: 1px;
	margin: 0 4px; }

.header-description-container {
	display: flex;
	width: 931px;
	height: 89px;
	background: rgba(210, 216, 221,0.1);
	border-radius: 6px;
	justify-content: flex-start;
	align-items: center;
	margin-left : 35px; }

.header-description {
	display: flex;
	width: 794px;
	height: 69px;
	justify-content: flex-start;
	align-items: center;
	padding-left: 20px; }

.header-content {
	width: calc(100% - 44px);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding-left: 10px; }

.header-close {
	width: 44px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 5px;
	cursor: pointer; }

.content-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	align-self: auto;
	padding: 20px 60px 10px 60px;
	flex-wrap: wrap;
	overflow-y: auto; }


.close-image {
	width: 17px;
	height: 18px; }

.reports-container {
	display: flex;
	flex-direction: column;
	height: calc(100% - 45px); }

@media ( min-width: 1440px ) {
	.reportPopup-container {
		width: 1125px;
		height: 607.5px;
		border-radius: 5.625px; }

	.header-container {
		height: 49.5px; }

	.header-line {
		width: calc(100% - 9px);
		height: 1.125px;
		margin: 0 4.5px; }

	.header-description-container {
		width: 1047.375px;
		height: 100.125px;
		border-radius: 6.75px;
		margin-left: 39.375px; }

	.header-description {
		width: 893.25px;
		height: 77.625px;
		padding-left: 22.5px; }

	.header-content {
		width: calc(100% - 49.5px);
		padding-left: 11.25px; }

	.header-close {
		width: 49.5px;
		border-bottom-left-radius: 5.625px; }

	.content-container {
		padding: 22.5px 67.5px 11.25px 67.5px; }


	.close-image {
		width: 19.125px;
		height: 20.25px; }

	.reports-container {
		height: calc(100% - 50.625px); } }

@media ( min-width: 1600px ) {
	.reportPopup-container {
		width: 1250px;
		height: 675px;
		border-radius: 6.25px; }

	.header-container {
		height: 55px; }

	.header-line {
		width: calc(100% - 10px);
		height: 1.25px;
		margin: 0 5px; }

	.header-description-container {
		width: 1163.75px;
		height: 111.25px;
		border-radius: 7.5px;
		margin-left : 43.75px; }

	.header-description {
		width: 992.5px;
		height: 86.25px;
		padding-left: 25px; }

	.header-content {
		width: calc(100% - 55px);
		padding-left: 12.5px; }

	.header-close {
		width: 55px;
		border-bottom-left-radius: 6.25px; }

	.content-container {
		padding: 25px 75px 12.5px 75px; }

	.close-image {
		width: 21.25px;
		height: 22.5px; }

	.reports-container {
		height: calc(100% - 56.25px); } }

@media ( min-width: 1920px ) {
	.reportPopup-container {
		width: 1500px;
		height: 810px;
		border-radius: 7.5px; }

	.header-container {
		height: 66px; }

	.header-line {
		width: calc(100% - 12px);
		height: 1.5px;
		margin: 0 6px; }

	.header-description-container {
		width: 1396.5px;
		height: 133.5px;
		border-radius: 9px;
		margin-left : 52.5px; }

	.header-description {
		width: 1191px;
		height: 103.5px;
		padding-left: 30px; }

	.header-content {
		width: calc(100% - 66px);
		padding-left: 15px; }

	.header-close {
		width: 66px;
		border-bottom-left-radius: 7.5px; }

	.content-container {
		padding: 30px 90px 15px 90px; }


	.close-image {
		width: 25.5px;
		height: 27px; }

	.reports-container {
		height: calc(100% - 67.5px); } }


@media ( min-width: 2560px ) {
	.reportPopup-container {
		width: 2000px;
		height: 1080px;
		border-radius: 10px; }

	.header-container {
		height: 88px; }

	.header-line {
		width: calc(100% - 16px);
		height: 2px;
		margin: 0 8px; }

	.header-description-container {
		width: 1862px;
		height: 178px;
		border-radius: 12px;
		margin-left : 70px; }

	.header-description {
		width: 1588px;
		height: 138px;
		padding-left: 40px; }

	.header-content {
		width: calc(100% - 88px);
		padding-left: 20px; }

	.header-close {
		width: 88px;
		border-bottom-left-radius: 10px; }

	.content-container {
		padding: 40px 120px 20px 120px; }

	.close-image {
		width: 34px;
		height: 36px; }

	.reports-container {
		height: calc(100% - 90px); } }
