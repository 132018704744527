@import '~styles/mixins.sass';

.startSimPopup-container {
	width: 570px;
	height: 330px;
	overflow: hidden;
	border-radius: 12px;
	box-sizing: border-box; }

.content-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 30px;
	@include display-flex-center-mixin; }

// .image-container
// 	width: 210px
// 	height: 220px

.content-details {
	width: calc(100%);
	box-sizing: border-box;
	@include display-flex-center-mixin;
	flex-direction: column; }

.advisor-details {
	display: flex;
	align-items: center;
	margin-bottom: 10px; }

.advisor-image {
	width: 70px;
	height: 70px; }

.advisor-name {
	padding-left: 20px; }

.advisor-desc {
	margin-bottom: 20px;
	text-align: center; }

.button-container {
	margin-bottom: 5px; }

.button {
	padding: 3px 20px;
	@include display-flex-center-mixin;
	border-radius: 6px;
	width: fit-content;
	height: 34px;
	cursor: pointer; }

.timer-text {
	padding-bottom: 3px; }

@media  (min-width: 1440px) {
	.startSimPopup-container {
		width: 641.25px;
		height: 371.25px;
		border-radius: 13.5px; }

	.content-container {
		padding: 33.75px; }

	.advisor-details {
		margin-bottom: 11.25px; }

	.advisor-image {
		width: 78.75px;
		height: 78.75px; }

	.advisor-name {
		padding-left: 22.5px; }

	.advisor-desc {
		margin-bottom: 22.5px; }

	.button-container {
		margin-bottom: 5.625px; }

	.button {
		padding: 3.375px 22.5px;
		border-radius: 6.75px;
		height: 38.25px; }

	.timer-text {
		padding-bottom: 3.375px; } }

@media  (min-width: 1600px) {
	.startSimPopup-container {
		width: 712.5px;
		height: 412.5px;
		border-radius: 15px; }

	.content-container {
		padding: 37.5px; }

	.advisor-details {
		margin-bottom: 12.5px; }

	.advisor-image {
		width: 87.5px;
		height: 87.5px; }

	.advisor-name {
		padding-left: 25px; }

	.advisor-desc {
		margin-bottom: 25px; }

	.button-container {
		margin-bottom: 6.25px; }

	.button {
		padding: 3.75px 25px;
		border-radius: 7.5px;
		height: 42.5px; }

	.timer-text {
		padding-bottom: 3.75px; } }

@media  (min-width: 1920px) {
	.startSimPopup-container {
		width: 855px;
		height: 495px;
		border-radius: 18px; }

	.content-container {
		padding: 45px; }

	.advisor-details {
		margin-bottom: 15px; }

	.advisor-image {
		width: 105px;
		height: 105px; }

	.advisor-name {
		padding-left: 30px; }

	.advisor-desc {
		margin-bottom: 30px; }

	.button-container {
		margin-bottom: 7.5px; }

	.button {
		padding: 4.5px 30px;
		border-radius: 9px;
		height: 51px; }

	.timer-text {
		padding-bottom: 4.5px; } }

@media  (min-width: 2560px) {
	.startSimPopup-container {
		width: 1140px;
		height: 660px;
		border-radius: 24px; }

	.content-container {
		padding: 60px; }

	.advisor-details {
		margin-bottom: 20px; }

	.advisor-image {
		width: 140px;
		height: 140px; }

	.advisor-name {
		padding-left: 40px; }

	.advisor-desc {
		margin-bottom: 40px; }

	.button-container {
		margin-bottom: 10px; }

	.button {
		padding: 6px 40px;
		border-radius: 12px;
		height: 68px; }

	.timer-text {
		padding-bottom: 6px; } }
