@import '~styles/mixins.sass';

.header-container {
	@include display-flex-center-mixin;
	width: 100%;
	height: 50px;
	min-width: 1280px;
	@extend %row;
	position: relative; }

.header-content {
	@include col-header;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	height: 100%;
	width: 100%; }

.left-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	padding: 0; }

.middle-header {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0; }

.right-header {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	height: 100%;
	padding-bottom: 7px;
	box-sizing: border-box; }

.middle-header-select-actors-instruction {
	display: flex;
	z-index: 52;
	width: 700px;
	padding: 4px;
	border-radius: 12px;
	justify-content: center; }

@media ( min-width: 1440px ) {
	.header-container {
		height: 56.25px;
		min-width: 1440px; }

	.right-header {
		padding-bottom: 7.875px; }

	.middle-header-select-actors-instruction {
		width: 787.5px;
		padding: 4.5px;
		border-radius: 13.5px; } }

@media ( min-width: 1600px ) {
	.header-container {
		height: 62.5px;
		min-width: 1600px; }

	.right-header {
		padding-bottom: 8.75px; }

	.middle-header-select-actors-instruction {
		width: 875px;
		padding: 5px;
		border-radius: 15px; } }

@media ( min-width: 1920px ) {
	.header-container {
		height: 75px;
		min-width: 1920px; }

	.right-header {
		padding-bottom: 10.5px; }

	.middle-header-select-actors-instruction {
		width: 1050px;
		padding: 6px;
		border-radius: 18px; } }

@media ( min-width: 2560px ) {
	.header-container {
		height: 100px;
		min-width: 2560px; }

	.right-header {
		padding-bottom: 14px; }

	.middle-header-select-actors-instruction {
		width: 1400px;
		padding: 8px;
		border-radius: 24px; } }
