@import '~styles/mixins.sass';

.actor-metric {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	margin-top: 10px; }

.actor-metric-image {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
	background-color: rgba(0, 0, 0, 0.2);
	@include display-flex-center-mixin; }

.actor-metric-name {
    padding-left: 7px;
    font-size: 10px; }

.actor-metric-change {
    padding-left: 7px;
    font-size: 10px; }

.actor-change-image {
	position: absolute;
	bottom: -5px;
	right: -5px;
	width: 10px {
    height: 10px; } }

.impacted-actor {
    width: 100%;
    height: 100%;
    border-radius: 50%; }

@media  (min-width: 1440px) {
	.actor-metric {
		margin-right: 22.5px;
		margin-top: 11.25px; }

	.actor-metric-image {
		width: 22.5px;
		height: 22.5px; }

	.actor-metric-name {
		padding-left: 7.875px;
		font-size: 11.25px; }

	.actor-metric-change {
		padding-left: 7.875px;
		font-size: 11.25px; }

	.actor-change-image {
		bottom: -5.625px;
		right: -5.625px;
		width: 11.25px;
		height: 11.25px; } }

@media  (min-width: 1600px) {
	.actor-metric {
		margin-right: 25px;
		margin-top: 12.5px; }

	.actor-metric-image {
		width: 25px;
		height: 25px; }

	.actor-metric-name {
		padding-left: 8.75px;
		font-size: 12.5px; }

	.actor-metric-change {
		padding-left: 8.75px;
		font-size: 12.5px; }

	.actor-change-image {
		bottom: -6.25px;
		right: -6.25px;
		width: 12.5px;
		height: 12.5px; } }

@media  (min-width: 1920px) {
	.actor-metric {
		margin-right: 30px;
		margin-top: 15px; }

	.actor-metric-image {
		width: 30px;
		height: 30px; }

	.actor-metric-name {
		padding-left: 10.5px;
		font-size: 15px; }

	.actor-metric-change {
		padding-left: 10.5px;
		font-size: 15px; }

	.actor-change-image {
		bottom: -7.5px;
		right: -7.5px;
		width: 15px;
		height: 15px; } }

@media  (min-width: 2560px) {
	.actor-metric {
		margin-right: 40px;
		margin-top: 20px; }

	.actor-metric-image {
		width: 40px;
		height: 40px; }

	.actor-metric-name {
		padding-left: 14px;
		font-size: 20px; }

	.actor-metric-change {
		padding-left: 14px;
		font-size: 20px; }

	.actor-change-image {
		bottom: -10px;
		right: -10px;
		width: 20px;
		height: 20px; } }
