@import '~styles/mixins.sass';

.presentation-card-container {
	// margin: 0 10px 10px 0
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	// padding: 0px 7px 5px 7px
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-radius: 4px; }

.title {
	width: 100%;
	height: 30px;
	padding: 0 7px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.footer {
	width: 100%;
	height: 55px;
	padding: 0 7px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	text-align: center; }

.title-line {
	width: 65%;
	height: 1px; }

.content {
	// margin-top: 5px
	width: 100%;
	height: calc(100% - 65px); }

.image-type {
	display: flex;
	align-items: center;
	justify-content: center; }

.option-image {
	width: 45px;
	height: 45px; }

.option-operation {
	margin: 0 10px; }

.point {
	width: 100%;
	box-sizing: border-box;
	padding: 6px 2px 2px 25px;
	position: relative; }

.graph-heading {
	width: 100%;
	height: 25px;
	text-align: center;
	padding-top: 4px;
	box-sizing: border-box; }

.graphs-container {
	width: 100%;
	height: calc(100% - 25px);
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 5px 20px 0px 20px; }

.each-graph-container {
	height: 100%;
	box-sizing: border-box;
	padding: 5px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; }

.graph-bar-container {
	width: 40px;
	height: calc(100% - 35px);
	display: flex;
	align-items: flex-end;
	justify-content: center; }

.graph-bar {
	position: relative;
	width: 100%; }

.graph-bar-value {
	position: absolute;
	top: -20px;
	left: 8px; }

.graph-bar-text {
	width: 100%;
	height: 35px;
	box-sizing: border-box;
	padding-top: 3px;
	text-align: center;
	word-break: break-word; }

@media ( min-width: 1440px ) {
	.presentation-card-container {
		border-radius: 4.5px; }

	.title {
		height: 33.75px;
		padding: 0 7.875px; }

	.footer {
		height: 61.875px;
		padding: 0 7.875px; }

	.title-line {
		height: 1.125px; }

	.content {
		height: calc(100% - 73.125px); }

	.option-image {
		width: 50.625px;
		height: 50.625px; }

	.option-operation {
		margin: 0 11.25px; }

	.point {
		padding: 6.75px 2.25px 2.25px 28.125px; }

	.graph-heading {
		height: 28.125px;
		padding-top: 4.5px; }

	.graphs-container {
		height: calc(100% - 28.125px);
		padding: 5.625px 22.5px 0px 22.5px; }

	.each-graph-container {
		padding: 5.625px 11.25px; }

	.graph-bar-container {
		width: 45px;
		height: calc(100% - 39.375px); }

	.graph-bar-value {
		top: -22.5px;
		left: 9px; }

	.graph-bar-text {
		height: 39.375px;
		padding-top: 3.375px; } }

@media ( min-width: 1600px ) {
	.presentation-card-container {
		border-radius: 5px; }

	.title {
		height: 37.5px;
		padding: 0 8.75px; }

	.footer {
		height: 68.75px;
		padding: 0 8.75px; }

	.title-line {
		height: 1.25px; }

	.content {
		height: calc(100% - 81.25px); }

	.option-image {
		width: 56.25px;
		height: 56.25px; }

	.option-operation {
		margin: 0 12.5px; }

	.point {
		padding: 7.5px 2.5px 2.5px 31.25px; }

	.graph-heading {
		height: 31.25px;
		padding-top: 5px; }

	.graphs-container {
		height: calc(100% - 31.25px);
		padding: 6.25px 25px 0px 25px; }

	.each-graph-container {
		padding: 6.25px 12.5px; }

	.graph-bar-container {
		width: 50px;
		height: calc(100% - 43.75px); }

	.graph-bar-value {
		top: -25px;
		left: 10px; }

	.graph-bar-text {
		height: 43.75px;
		padding-top: 3.75px; } }

@media ( min-width: 1920px ) {
	.presentation-card-container {
		border-radius: 6px; }

	.title {
		height: 45px;
		padding: 0 10.5px; }

	.footer {
		height: 82.5px;
		padding: 0 10.5px; }

	.title-line {
		height: 1.5px; }

	.content {
		height: calc(100% - 97.5px); }

	.option-image {
		width: 67.5px;
		height: 67.5px; }

	.option-operation {
		margin: 0 15px; }

	.point {
		padding: 9px 3px 3px 37.5px; }

	.graph-heading {
		height: 37.5px;
		padding-top: 6px; }

	.graphs-container {
		height: calc(100% - 37.5px);
		padding: 7.5px 30px 0px 30px; }

	.each-graph-container {
		padding: 7.5px 15px; }

	.graph-bar-container {
		width: 60px;
		height: calc(100% - 52.5px); }

	.graph-bar-value {
		top: -30px;
		left: 12px; }

	.graph-bar-text {
		height: 52.5px;
		padding-top: 4.5px; } }

@media ( min-width: 2560px ) {
	.presentation-card-container {
		border-radius: 8px; }

	.title {
		height: 60px;
		padding: 0 14px; }

	.footer {
		height: 110px;
		padding: 0 14px; }

	.title-line {
		height: 2px; }

	.content {
		height: calc(100% - 130px); }

	.option-image {
		width: 90px;
		height: 90px; }

	.option-operation {
		margin: 0 20px; }

	.point {
		padding: 12px 4px 4px 50px; }

	.graph-heading {
		height: 50px;
		padding-top: 8px; }

	.graphs-container {
		height: calc(100% - 50px);
		padding: 10px 40px 0px 40px; }

	.each-graph-container {
		padding: 10px 20px; }

	.graph-bar-container {
		width: 80px;
		height: calc(100% - 70px); }

	.graph-bar-value {
		top: -40px;
		left: 16px; }

	.graph-bar-text {
		height: 70px;
		padding-top: 6px; } }
