@import '~styles/mixins.sass';

.main-component {
	width: 100%;
	height: 270px;
	position: relative; }

.layer1 {
	width: 100%;
	height: 270px;
	position: absolute;
	z-index: 2; }

.layer2 {
	width: 100%;
	height: 270px;
	position: absolute;
	z-index: 1; }
