body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: white
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f6f5f7;
	border-radius: 8px;
	box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.6);
}

.flex-box-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.base-app-box {
	width: 100%;
	background: linear-gradient(102.06deg, #3F4467 6.54%, #303555 91.21%);
	height: 100vh;
	min-width: 1270px;
	min-height: 670px;
	position: relative;
	overflow: hidden;
	-webkit-touch-callout: none;
	/* iOS Safari*/
	-webkit-user-select: none;
	-webkit-user-drag: none;
	/* Chrome/Safari/Opera*/
	-khtml-user-select: none;
	-khtml-user-drag: none;
	/* Konqueror*/
	-moz-user-select: none;
	-moz-user-drag: none;
	/* Firefox*/
	-ms-user-select: none;
	-ms-user-drag: none;
	/* Internet Explorer/Edge*/
	user-select: none;
	user-drag: none;
}

/* React joyride tooltip */
.react-joyride__spotlight {
	width: fit-content;
	background-clip: content-box;
	box-shadow: inset 0 0 0 var(--padding) rgba(0, 0, 0, 0);
	box-sizing: border-box;
	background: #FD1E510C;
}

.react-joyride__tooltip {
	padding: 0px 0 10px 0 !important;
	width: 300px !important
}

.react-joyride__tooltip > div > div {
	flex: 0 0 0% !important;
}

.react-joyride__overlay {
	cursor: default !important;
}

@media only screen and (max-width: 1270px) {
	.base-app-box {
		height: calc(100vh - 15px);
	}
  }

@media only screen and (min-width: 1440px) {
	.base-app-box {
		min-width: 1430px;
		min-height: 753.75px;
	}

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
		border-radius: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 7.5px;
		box-shadow: inset 0 0 1.875px grey;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-radius: 7.5px;
	}
		
	.react-joyride__tooltip {
		padding: 0px 0 11.25px 0 !important;
		width: 337.5px !important
	}
	
	.react-joyride__tooltip > div > div {
		flex: 0 0 0% !important;
	}
}

@media only screen and (min-width: 1600px) {
	.base-app-box {
		min-width: 1590px;
		min-height: 837px;
	}

		/* width */
	::-webkit-scrollbar {
		width: 10px;
		border-radius: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 10px;
		box-shadow: inset 0 0 2.5px grey;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
	}


	.react-joyride__tooltip {
		padding: 0px 0 12.5px 0 !important;
		width: 375px !important
	}
	
	.react-joyride__tooltip > div > div {
		flex: 0 0 0% !important;
	}
}

@media only screen and (min-width: 1920px) {
	.base-app-box {
		min-width: 1910px;
		min-height: 1005px;
	}

	/* width */
	::-webkit-scrollbar {
		width: 10px;
		border-radius: 10px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 10px;
		box-shadow: inset 0 0 2.5px grey;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
	}
		
	.react-joyride__tooltip {
		padding: 0px 0 15px 0 !important;
		width: 450px !important
	}
	
	.react-joyride__tooltip > div > div {
		flex: 0 0 0% !important;
	}
}

@media only screen and (min-width: 2560px) {
	.base-app-box {
		min-width: 2544px;
		min-height: 1339.2px;
	}

		/* width */
	::-webkit-scrollbar {
		width: 16px;
		border-radius: 16px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 16px;
		box-shadow: inset 0 0 4px grey;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		border-radius: 16px;
	}


	.react-joyride__tooltip {
		padding: 0px 0 20px 0 !important;
		width: 600px !important
	}
	
	.react-joyride__tooltip > div > div {
		flex: 0 0 0% !important;
	}
}