@import '~styles/mixins.sass';

.container {
	width: 100%;
	padding: 15px 10px;
	box-sizing: border-box;
	max-height: 47vh;
	overflow-y: auto; }

.ddb-container {
	width: 100%;
	padding: 15px 10px;
	box-sizing: border-box; }

.option-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	padding: 5px; }

.option-select-box {
	box-sizing: border-box;
	margin-top: 5px;
	width: 12px;
	height: 12px; }

.option-content {
	width: calc(100% - 12px);
	box-sizing: border-box;
	padding-left: 7px; }

.filter-circle {
	width: 12px;
	height: 12px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content:  center; }

.filter-selected {
	width: 4px;
	height: 4px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer; }


.ddb-option-selection-box {
	width: 100%;
	padding: 10px 10px;
	box-sizing: border-box;
	position: relative; }

.ddb-option-selected {
	width: 100%;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-sizing: border-box;
	padding: 0 5px; }

.ddb-options-container {
	position: absolute;
	left: 102%;
	top: -75px;
	box-sizing: border-box;
	padding: 10px 0 0 10px;
	width: 605px;
	display: flex;
	flex-wrap: wrap; }

.ddb-option-container {
	width: 285px;
	height: 220px;
	margin-right: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	// padding: 5px
	position: relative;
	cursor: pointer; }

.ddb-option-select-tick {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 18px;
	height: 18px; }

.ddb-option-content {
	width: 100%;
	height: 100%; }

.ddb-option-box-text {
	width: calc(100% - 18px);
	box-sizing: border-box;
	padding-right: 5px; }

.ddb-option-box-arrow {
	width: 18px;
	height: 18px; }

@media ( min-width: 1440px ) {
	.container {
		padding: 16.875px 11.25px; }

	.ddb-container {
		padding: 16.875px 11.25px; }

	.option-container {
		padding: 5.625px; }

	.option-select-box {
		margin-top: 5.625px;
		width: 13.5px;
		height: 13.5px; }

	.option-content {
		width: calc(100% - 13.5px);
		padding-left: 7.875px; }

	.filter-circle {
		width: 13.5px;
		height: 13.5px; }

	.filter-selected {
		width: 4.5px;
		height: 4.5px; }


	.ddb-option-selection-box {
		padding: 11.25px 11.25px; }

	.ddb-option-selected {
		border-radius: 4.5px;
		padding: 0 5.625px; }

	.ddb-options-container {
		top: -84.375px;
		padding: 11.25px 0 0 11.25px;
		width: 680.625px; }

	.ddb-option-container {
		width: 320.625px;
		height: 247.5px;
		margin-right: 11.25px;
		margin-bottom: 11.25px; }

	.ddb-option-select-tick {
		top: 11.25px;
		right: 11.25px;
		width: 20.25px;
		height: 20.25px; }

	.ddb-option-box-text {
		width: calc(100% - 20.25px);
		padding-right: 5.625px; }

	.ddb-option-box-arrow {
		width: 20.25px;
		height: 20.25px; } }

@media ( min-width: 1600px ) {
	.container {
		padding: 18.75px 12.5px; }

	.ddb-container {
		padding: 18.75px 12.5px; }

	.option-container {
		padding: 6.25px; }

	.option-select-box {
		margin-top: 6.25px;
		width: 15px;
		height: 15px; }

	.option-content {
		width: calc(100% - 15px);
		padding-left: 8.75px; }

	.filter-circle {
		width: 15px;
		height: 15px; }

	.filter-selected {
		width: 5px;
		height: 5px; }


	.ddb-option-selection-box {
		padding: 12.5px 12.5px; }

	.ddb-option-selected {
		border-radius: 5px;
		padding: 0 6.25px; }

	.ddb-options-container {
		top: -93.75px;
		padding: 12.5px 0 0 12.5px;
		width: 756.25px; }

	.ddb-option-container {
		width: 356.25px;
		height: 275px;
		margin-right: 12.5px;
		margin-bottom: 12.5px; }

	.ddb-option-select-tick {
		top: 12.5px;
		right: 12.5px;
		width: 22.5px;
		height: 22.5px; }

	.ddb-option-box-text {
		width: calc(100% - 22.5px);
		padding-right: 6.25px; }

	.ddb-option-box-arrow {
		width: 22.5px;
		height: 22.5px; } }

@media ( min-width: 1920px ) {
	.container {
		padding: 22.5px 15px; }

	.ddb-container {
		padding: 22.5px 15px; }

	.option-container {
		padding: 7.5px; }

	.option-select-box {
		margin-top: 7.5px;
		width: 18px;
		height: 18px; }

	.option-content {
		width: calc(100% - 18px);
		padding-left: 10.5px; }

	.filter-circle {
		width: 18px;
		height: 18px; }

	.filter-selected {
		width: 6px;
		height: 6px; }


	.ddb-option-selection-box {
		padding: 15px 15px; }

	.ddb-option-selected {
		border-radius: 6px;
		padding: 0 7.5px; }

	.ddb-options-container {
		top: -112.5px;
		padding: 15px 0 0 15px;
		width: 907.5px; }

	.ddb-option-container {
		width: 427.5px;
		height: 330px;
		margin-right: 15px;
		margin-bottom: 15px; }

	.ddb-option-select-tick {
		top: 15px;
		right: 15px;
		width: 27px;
		height: 27px; }

	.ddb-option-box-text {
		width: calc(100% - 27px);
		padding-right: 7.5px; }

	.ddb-option-box-arrow {
		width: 27px;
		height: 27px; } }


@media ( min-width: 2560px ) {
	.container {
		padding: 30px 20px; }

	.ddb-container {
		padding: 30px 20px; }

	.option-container {
		padding: 10px; }

	.option-select-box {
		margin-top: 10px;
		width: 24px;
		height: 24px; }

	.option-content {
		width: calc(100% - 24px);
		padding-left: 14px; }

	.filter-circle {
		width: 24px;
		height: 24px; }

	.filter-selected {
		width: 8px;
		height: 8px; }


	.ddb-option-selection-box {
		padding: 20px 20px; }

	.ddb-option-selected {
		border-radius: 8px;
		padding: 0 10px; }

	.ddb-options-container {
		top: -150px;
		padding: 20px 0 0 20px;
		width: 1210px; }

	.ddb-option-container {
		width: 570px;
		height: 440px;
		margin-right: 20px;
		margin-bottom: 20px; }

	.ddb-option-select-tick {
		top: 20px;
		right: 20px;
		width: 36px;
		height: 36px; }

	.ddb-option-box-text {
		width: calc(100% - 36px);
		padding-right: 10px; }

	.ddb-option-box-arrow {
		width: 36px;
		height: 36px; } }
