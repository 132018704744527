@import '~styles/mixins.sass';

.startSimPopup-container {
	width: 728px;
	height: 290px;
	overflow: hidden;
	border-radius: 12px;
	box-sizing: border-box; }

.content-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 30px;
	@include display-flex-center-mixin; }

.image-container {
	width: 210px;
	height: 220px; }

.content-details {
	width: calc(100% - 210px);
	box-sizing: border-box;
	padding-left: 30px; }

.advisor-details {
	display: flex;
	align-items: center;
	margin-bottom: 10px; }

.advisor-image {
	width: 46px;
	height: 46px;
	border-radius: 50%;
	overflow: hidden; }

.advisor-name {
	padding-left: 20px; }

.advisor-desc {
	margin-bottom: 20px; }

.duration-list {
	margin-bottom: 20px; }

.bullet-container {
	width: 100%;
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.bullet-point {
	width: 20px; }

.bullet-desc {
	padding-left: 5px; }

.button-container {
	margin-bottom: 5px; }

.button {
	padding: 0 15px;
	@include display-flex-center-mixin;
	border-radius: 6px;
	width: fit-content;
	height: 32px;
	cursor: pointer; }

.timer-text {
	padding-bottom: 3px; }

@media  (min-width: 1440px) {
	.startSimPopup-container {
		width: 819px;
		height: 326.25px;
		border-radius: 13.5px; }

	.content-container {
		padding: 33.75px; }

	.image-container {
		width: 236.25px;
		height: 247.5px; }

	.content-details {
		width: calc(100% - 236.25px);
		padding-left: 33.75px; }

	.advisor-details {
		margin-bottom: 11.25px; }

	.advisor-image {
		width: 51.75px;
		height: 51.75px; }

	.advisor-name {
		padding-left: 22.5px; }

	.advisor-desc {
		margin-bottom: 22.5px; }

	.duration-list {
		margin-bottom: 22.5px; }

	.bullet-container {
		margin-top: 5.625px; }

	.bullet-point {
		width: 22.5px; }

	.bullet-desc {
		padding-left: 5.625px; }

	.button-container {
		margin-bottom: 5.625px; }

	.button {
		padding: 0 16.875px;
		border-radius: 6.75px;
		height: 36px; }

	.timer-text {
		padding-bottom: 3.375px; } }

@media  (min-width: 1600px) {
	.startSimPopup-container {
		width: 910px;
		height: 362.5px;
		border-radius: 15px; }

	.content-container {
		padding: 37.5px; }

	.image-container {
		width: 262.5px;
		height: 275px; }

	.content-details {
		width: calc(100% - 262.5px);
		padding-left: 37.5px; }

	.advisor-details {
		margin-bottom: 12.5px; }

	.advisor-image {
		width: 57.5px;
		height: 57.5px; }

	.advisor-name {
		padding-left: 25px; }

	.advisor-desc {
		margin-bottom: 25px; }

	.duration-list {
		margin-bottom: 25px; }

	.bullet-container {
		margin-top: 6.25px; }

	.bullet-point {
		width: 25px; }

	.bullet-desc {
		padding-left: 6.25px; }

	.button-container {
		margin-bottom: 6.25px; }

	.button {
		padding: 0 18.75px;
		border-radius: 7.5px;
		height: 40px; }

	.timer-text {
		padding-bottom: 3.75px; } }

@media  (min-width: 1920px) {
	.startSimPopup-container {
		width: 1092px;
		height: 435px;
		border-radius: 18px; }

	.content-container {
		padding: 45px; }

	.image-container {
		width: 315px;
		height: 330px; }

	.content-details {
		width: calc(100% - 315px);
		padding-left: 45px; }

	.advisor-details {
		margin-bottom: 15px; }

	.advisor-image {
		width: 69px;
		height: 69px; }

	.advisor-name {
		padding-left: 30px; }

	.advisor-desc {
		margin-bottom: 30px; }

	.duration-list {
		margin-bottom: 30px; }

	.bullet-container {
		margin-top: 7.5px; }

	.bullet-point {
		width: 30px; }

	.bullet-desc {
		padding-left: 7.5px; }

	.button-container {
		margin-bottom: 7.5px; }

	.button {
		padding: 0 22.5px;
		border-radius: 9px;
		height: 48px; }

	.timer-text {
		padding-bottom: 4.5px; } }

@media  (min-width: 2560px) {
	.startSimPopup-container {
		width: 1456px;
		height: 580px;
		border-radius: 24px; }

	.content-container {
		padding: 60px; }

	.image-container {
		width: 420px;
		height: 440px; }

	.content-details {
		width: calc(100% - 420px);
		padding-left: 60px; }

	.advisor-details {
		margin-bottom: 20px; }

	.advisor-image {
		width: 92px;
		height: 92px; }

	.advisor-name {
		padding-left: 40x; }

	.advisor-desc {
		margin-bottom: 40px; }

	.duration-list {
		margin-bottom: 40px; }

	.bullet-container {
		margin-top: 10px; }

	.bullet-point {
		width: 40px; }

	.bullet-desc {
		padding-left: 10px; }

	.button-container {
		margin-bottom: 10px; }

	.button {
		padding: 0 30px;
		border-radius: 12px;
		height: 64px; }

	.timer-text {
		padding-bottom: 6px; } }
