@import '~styles/mixins.sass';

.user-profile-container-with-options {
	@include display-flex-center-mixin;
	position: relative;
	outline: none;
	padding-left: 15px; }

.user-profile-container {
	@include display-flex-center-mixin;
	// width: 40px
	cursor: pointer; }

.user-profile-image-container {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	@include display-flex-center-mixin; }

.user-profile-image {
	width: 14px;
	height: 14px; }

.user-profile-arrow-container {
	margin-left: 5px;
	width: 8px;
	height: 6px; }

.user-profile-option-container {
	position: absolute;
	width: 250px;
	// height: 110px
	z-index: 50;
	top: 35px;
	left: -190px;
	overflow: hidden; }

.each-option {
	width: 100%;
	padding: 10px 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.each-option-image {
	width: 20px;
	height: 18px;
	margin-right: 5px; }

.user-name-container {
	width: 100%;
	box-sizing: border-box;
	padding: 5px 20px; }

.user-name {
	width: 100%;
	text-align: left;
	padding: 5px 0;
	word-break: break-all; }

@media ( min-width: 1440px ) {
	.user-profile-container-with-options {
		padding-left: 16.875px; }

	.user-profile-image-container {
		width: 27px;
		height: 27px; }

	.user-profile-image {
		width: 15.75px;
		height: 15.75px; }

	.user-profile-arrow-container {
		margin-left: 5.625px;
		width: 9px;
		height: 6.75px; }

	.user-profile-option-container {
		width: 281.25px;
		top: 39.375px;
		left: -213.75px; }

	.each-option {
		padding: 11.25px 22.5px; }

	.each-option-image {
		width: 22.5px;
		height: 20.25px;
		margin-right: 5.625px; }

	.user-name-container {
		padding: 5.625px 22.5px; }

	.user-name {
		padding: 5.625px 0; } }

@media ( min-width: 1600px ) {
	.user-profile-container-with-options {
		padding-left: 18.75px; }

	.user-profile-image-container {
		width: 30px;
		height: 30px; }

	.user-profile-image {
		width: 17.5px;
		height: 17.5px; }

	.user-profile-arrow-container {
		margin-left: 6.25px;
		width: 10px;
		height: 7.5px; }

	.user-profile-option-container {
		width: 312.5px;
		top: 43.75px;
		left: -237.5px; }

	.each-option {
		padding: 12.5px 25px; }

	.each-option-image {
		width: 25px;
		height: 22.5px;
		margin-right: 6.25px; }

	.user-name-container {
		padding: 6.25px 25px; }

	.user-name {
		padding: 6.25px 0; } }

@media ( min-width: 1920px ) {
	.user-profile-container-with-options {
		padding-left: 22.5px; }

	.user-profile-image-container {
		width: 36px;
		height: 36px; }

	.user-profile-image {
		width: 21px;
		height: 21px; }

	.user-profile-arrow-container {
		margin-left: 7.5px;
		width: 12px;
		height: 9px; }

	.user-profile-option-container {
		width: 375px;
		top: 52.5px;
		left: -285px; }

	.each-option {
		padding: 15px 30px; }

	.each-option-image {
		width: 30px;
		height: 27px;
		margin-right: 7.5px; }

	.user-name-container {
		padding: 7.5px 30px; }

	.user-name {
		padding: 7.5px 0; } }

@media ( min-width: 2560px ) {
	.user-profile-container-with-options {
		padding-left: 30px; }

	.user-profile-image-container {
		width: 48px;
		height: 48px; }

	.user-profile-image {
		width: 28px;
		height: 28px; }

	.user-profile-arrow-container {
		margin-left: 10px;
		width: 16px;
		height: 12px; }

	.user-profile-option-container {
		width: 500px;
		top: 70px;
		left: -380px; }

	.each-option {
		padding: 20px 40px; }

	.each-option-image {
		width: 40px;
		height: 36px;
		margin-right: 10px; }

	.user-name-container {
		padding: 10px 40px; }

	.user-name {
		padding: 10px 0; } }

