@import '~styles/mixins.sass';

.container {
	width: 400px;
	height: 600px;
	background: white;
	margin-left: 50px;
	border-radius: 10px;
	position: absolute;
	top: 6%; }

.header-container {
	width: 100%;
	height: 50px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	position: relative;
	padding-left: 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.close-container {
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-color: #835AC5;
	padding: 12px;
	border-radius: 0px 10px;
	position: absolute;
	top: 0px;
	right: 0px; }

.header-image {
	width: 17px;
	height: 24px;
	margin-left: 5px; }

.header-text {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 15px; }

.header-image-text {
	display: flex;
	justify-content: flex-start; }

.mentor-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	box-shadow: 0px 0px 4px #000; }

.header-border-line {
	height: 0.5px;
	margin: 0px auto 10px;
	width: 90%; }

.tips-progress-container {
	height: 5px;
	display: flex;
	justify-content: space-between;
	margin: 0px 20px 15px; }

.tips-available {
	width: 7%;
	background-color: #FD5382; }

.tips-unavailable {
	width: 7%;
	background-color: #E3E6E9; }

.new-tip {
	position: absolute;
	top: -22px;
	right:  40%;
	width: 20%;
	height: 20px;
	display: flex;
	justify-content: center;
	border-radius: 16px; }

.tip-count {
	color: #A6B1BB; }

.tip-label {
	color: #14324B; }

.events-container {
	width: 100%;
	max-height: 538px;
	overflow-y: auto;
	box-sizing: border-box; }


.actions-container {
	width: 100%;
	padding: 7px;
	box-sizing: border-box; }

.actions-header-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer; }

.actions-header-text {
	padding-right: 7px; }

.actions-header-arrow {
	width: 12px;
	height: 12px; }

.actions-list {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap; }

.each-action {
	padding: 4px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	margin: 10px 7px 0px 2px;
	cursor: pointer; }

.action-image {
	width: 16px;
	height: 16px; }

.action-text {
	padding-left: 7px;
	line-height: 1; }

.message-container-left {
	width: 100%;
	padding: 10px 20px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	flex-direction: column; }

.left-arrow {
	width: 10px;
	height: 10px;
	box-sizing: border-box; }

.left-message {
	max-width: 100%;
	padding: 12px;
	border-radius: 5px; }

.left-message-header {
	display: flex;
	justify-content: space-between;
	position: relative; }

.message-container-right {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }


.right-arrow {
	width: 10px;
	height: 10px;
	box-sizing: border-box; }

.right-message {
	max-width: 70%;
	padding: 7px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px; }

.empty-container {
	width: 100%;
	height: 300px;
	@include display-flex-center-mixin;
	padding: 0 20px;
	box-sizing: border-box; }

@media ( min-width: 1440px ) {
	.container {
		width: 450px;
		height: 675px;
		margin-left: 56.25px;
		border-radius: 11.25px; }

	.header-container {
		height: 56.25px;
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px;
		padding-left: 22.5px; }

	.close-container {
		width: 22.5px;
		height: 22.5px;
		padding: 13.5px;
		border-radius: 0px 11.25px;
		top: 0px;
		right: 0px; }

	.header-image {
		width: 19.125px;
		height: 27px;
		margin-left: 5.625px; }

	.header-text {
		padding-top: 5.625px;
		padding-bottom: 5.625px;
		padding-left: 16.875px; }

	.mentor-image {
		height: 33.75px;
		width: 33.75px;
		box-shadow: 0px 0px 4.5px #000; }

	.header-border-line {
		height: 0.5625px;
		margin: 0px auto 11.25px; }

	.tips-progress-container {
		height: 5.625px;
		margin: 0px 22.5px 16.875px; }

	.new-tip {
		top: -24.75px;
		height: 22.5px;
		border-radius: 18px; }

	.events-container {
		max-height: 605.25px; }


	.actions-container {
		padding: 7.875px; }

	.actions-header-text {
		padding-right: 7.875px; }

	.actions-header-arrow {
		width: 13.5px;
		height: 13.5px; }

	.each-action {
		padding: 4.5px 13.5px;
		border-radius: 22.5px;
		margin: 11.25px 7.875px 0px 2.25px; }

	.action-image {
		width: 15px;
		height: 15px; }

	.action-text {
		padding-left: 7.875px; }

	.message-container-left {
		padding: 11.25px 22.5px; }

	.left-arrow {
		width: 11.25px;
		height: 11.25px; }

	.left-message {
		padding: 13.5px;
		border-radius: 5.625px; }

	.message-container-right {
		padding: 11.25px; }

	.right-arrow {
		width: 11.25px;
		height: 11.25px; }

	.right-message {
		padding: 7.875px;
		border-top-left-radius: 5.625px;
		border-bottom-left-radius: 5.625px;
		border-bottom-right-radius: 5.625px; }

	.empty-container {
		height: 337.5px;
		padding: 0 22.5px; } }

@media ( min-width: 1600px ) {
	.container {
		width: 500px;
		height: 750px;
		margin-left: 62.5px;
		border-radius: 12.5px; }

	.header-container {
		height: 62.5px;
		border-top-left-radius: 12.5px;
		border-top-right-radius: 12.5px;
		padding-left: 25px; }

	.close-container {
		width: 25px;
		height: 25px;
		padding: 15px;
		border-radius: 0px 12.5px;
		top: 0px;
		right: 0px; }

	.header-image {
		width: 21.25px;
		height: 30px;
		margin-left: 6.25px; }

	.header-text {
		padding-top: 6.25px;
		padding-bottom: 6.25px;
		padding-left: 18.75px; }

	.mentor-image {
		height: 37.5px;
		width: 37.5px;
		box-shadow: 0px 0px 5px #000; }

	.header-border-line {
		height: 0.625px;
		margin: 0px auto 12.5px; }

	.tips-progress-container {
		height: 6.25px;
		margin: 0px 25px 18.75px; }

	.new-tip {
		top: -27.5px;
		height: 25px;
		border-radius: 20px; }

	.events-container {
		max-height: 672.5px; }


	.actions-container {
		padding: 8.75px; }

	.actions-header-text {
		padding-right: 8.75px; }

	.actions-header-arrow {
		width: 15px;
		height: 15px; }

	.each-action {
		padding: 5px 15px;
		border-radius: 25px;
		margin: 12.5px 8.75px 0px 2.5px; }

	.action-image {
		width: 20px;
		height: 20px; }

	.action-text {
		padding-left: 8.75px; }

	.message-container-left {
		padding: 12.5px 25px; }

	.left-arrow {
		width: 12.5px;
		height: 12.5px; }

	.left-message {
		padding: 15px;
		border-radius: 6.25px; }

	.message-container-right {
		padding: 12.5px; }

	.right-arrow {
		width: 12.5px;
		height: 12.5px; }

	.right-message {
		padding: 8.75px;
		border-top-left-radius: 6.25px;
		border-bottom-left-radius: 6.25px;
		border-bottom-right-radius: 6.25px; }

	.empty-container {
		height: 375px;
		padding: 0 25px; } }

@media ( min-width: 1920px ) {
	.container {
		width: 600px;
		height: 900px;
		margin-left: 75px;
		border-radius: 15px; }

	.header-container {
		height: 75px;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		padding-left: 30px; }

	.close-container {
		width: 30px;
		height: 30px;
		padding: 18px;
		border-radius: 0px 15px;
		top: 0px;
		right: 0px; }

	.header-image {
		width: 25.5px;
		height: 36px;
		margin-left: 7.5px; }

	.header-text {
		padding-top: 7.5px;
		padding-bottom: 7.5px;
		padding-left: 22.5px; }

	.mentor-image {
		height: 45px;
		width: 45px;
		box-shadow: 0px 0px 6px #000; }

	.header-border-line {
		height: 0.75px;
		margin: 0px auto 15px; }

	.tips-progress-container {
		height: 7.5px;
		margin: 0px 30px 22.5px; }

	.new-tip {
		top: -33px;
		height: 30px;
		border-radius: 24px; }

	.events-container {
		max-height: 807px; }


	.actions-container {
		padding: 10.5px; }

	.actions-header-text {
		padding-right: 10.5px; }

	.actions-header-arrow {
		width: 18px;
		height: 18px; }

	.each-action {
		padding: 6px 18px;
		border-radius: 30px;
		margin: 15px 10.5px 0px 3px; }

	.action-image {
		width: 20px;
		height: 20px; }

	.action-text {
		padding-left: 10.5px; }

	.message-container-left {
		padding: 15px 30px; }

	.left-arrow {
		width: 15px;
		height: 15px; }

	.left-message {
		padding: 18px;
		border-radius: 7.5px; }

	.message-container-right {
		padding: 15px; }

	.right-arrow {
		width: 15px;
		height: 15px; }

	.right-message {
		padding: 10.5px;
		border-top-left-radius: 7.5px;
		border-bottom-left-radius: 7.5px;
		border-bottom-right-radius: 7.5px; }

	.empty-container {
		height: 450px;
		padding: 0 30px; } }

@media ( min-width: 2560px ) {
	.container {
		width: 800px;
		height: 1200px;
		margin-left: 100px;
		border-radius: 20px; }

	.header-container {
		height: 100px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		padding-left: 40px; }

	.close-container {
		width: 40px;
		height: 40px;
		padding: 24px;
		border-radius: 0px 20px;
		top: 0px;
		right: 0px; }

	.header-image {
		width: 34px;
		height: 48px;
		margin-left: 10px; }

	.header-text {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 30px; }

	.mentor-image {
		height: 60px;
		width: 60px;
		box-shadow: 0px 0px 8px #000; }

	.header-border-line {
		height: 1px;
		margin: 0px auto 20px; }

	.tips-progress-container {
		height: 10px;
		margin: 0px 40px 30px; }

	.new-tip {
		top: -44px;
		height: 40px;
		border-radius: 32px; }

	.events-container {
		max-height: 1076px; }


	.actions-container {
		padding: 14px; }

	.actions-header-text {
		padding-right: 14px; }

	.actions-header-arrow {
		width: 24px;
		height: 24px; }

	.each-action {
		padding: 8px 24px;
		border-radius: 40px;
		margin: 20px 14px 0px 4px; }

	.action-image {
		width: 32px;
		height: 32px; }

	.action-text {
		padding-left: 14px; }

	.message-container-left {
		padding: 20px 40px; }

	.left-arrow {
		width: 20px;
		height: 20px; }

	.left-message {
		padding: 24px;
		border-radius: 10px; }

	.message-container-right {
		padding: 20px; }

	.right-arrow {
		width: 20px;
		height: 20px; }

	.right-message {
		padding: 14px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 14px; }

	.empty-container {
		height: 600px;
		padding: 0 40px; } }
