@import '~styles/mixins.sass';

.complete-container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	box-sizing: border-box;
	// z-index: 50
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	-moz-backdrop-filter: blur(4px); }

.bottom-container {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end; }

.bottom-container-component {
	// height: 415px
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-end; }

.right-container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start; }

.left-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	box-sizing: border-box;
	padding-top: 60px; }

.center-container {
	display: flex;
	justify-content: center;
	align-items: center; }

.right-container-component {
	height: 100%;
	min-width: 500px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start; }

.left-container-component {
	height: calc(100% - 50px);
	width: 18vw;
	background: rgba(0, 0, 0, 0.3);
	min-width: 230px; }

@media ( min-width: 1440px ) {
	.left-container {
		padding-top: 67.5px; }

	.right-container-component {
		min-width: 562.5px; }

	.left-container-component {
		height: calc(100% - 56.25px);
		min-width: 258.75px; } }

@media ( min-width: 1600px ) {
	.left-container {
		padding-top: 75px; }

	.right-container-component {
		min-width: 625px; }

	.left-container-component {
		height: calc(100% - 62.5px);
		min-width: 287.5px; } }

@media ( min-width: 1920px ) {
	.left-container {
		padding-top: 90px; }

	.right-container-component {
		min-width: 750px; }

	.left-container-component {
		height: calc(100% - 75px);
		min-width: 345px; } }

@media ( min-width: 2560px ) {
	.left-container {
		padding-top: 120px; }

	.right-container-component {
		min-width: 1000px; }

	.left-container-component {
		height: calc(100% - 100px);
		min-width: 460px; } }
