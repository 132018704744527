@import '~styles/mixins.sass';

.container {
	width: 337px;
	background: white;
	margin-right: 100px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px; }

.header-container {
	width: 100%;
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
	position: relative;
	padding: 10px 12px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }

.close-container {
	position: absolute;
	width: 12px;
	height: 12px;
	top: 12px;
	right: 12px;
	cursor: pointer; }

.header-image {
	width: 17px;
	height: 24px; }

.header-text {
	padding-top: 4px;
	padding-left: 10px; }

.events-container {
	width: 100%;
	min-height: 200px;
	max-height: 350px;
	overflow-y: auto;
	padding: 10px 0; }

.sending-container {
	width: 100%;
	padding: 12px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.sending-text-box {
	width: 65%;
	padding: 4px;
	box-sizing: border-box;
	min-height: 32px; }

.send-button-container {
	width: 35%;
	@include display-flex-center-mixin; }

.send-button {
	padding: 0 7px;
	height: 32px;
	@include display-flex-center-mixin; }

.message-container-left {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }


.left-arrow {
	width: 10px;
	height: 10px;
	box-sizing: border-box; }

.left-message {
	max-width: 70%;
	padding: 7px;
	border-top-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px; }

.message-container-right {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }


.right-arrow {
	width: 10px;
	height: 10px;
	box-sizing: border-box; }

.right-message {
	max-width: 70%;
	padding: 7px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px; }
