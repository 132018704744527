@import '~styles/mixins.sass';

.metricAdoption {
	display: flex;
	flex-direction: row; }

.metric-container {
	width: 33.3%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 5px 0;
	position: relative; }

.metric-details {
	width: calc(100% - 16px);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start; }

.metric-bar {
	width: 5px;
	height: 100%;
	margin: 0 4px 0 7px; }

.metric-bar-popup {
	width: 10px;
	height: 100%;
	margin: 0 6px 0 8px; }

.metric-number {
	padding-top: 0px; }

.metric-name {
	padding-top: 0px; }

.metric-tick {
	margin-left: 2px;
	width: 8px;
	height: 8px; }

.metric-change {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center; }

.metric-change-animation {
	animation-name: animateChange;
	animation-timing-function: linear;
	animation-duration: 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards; }

@keyframes animateChange {
	0% {
		opacity: 0; }
	30% {
		opacity: 1; }
	60% {
		opacity: 1; }
	100% {
		opacity: 1; } }

@media  (min-width: 1440px) {
	.metric-container {
		padding: 5.625px 0; }

	.metric-details {
		width: calc(100% - 18px); }

	.metric-bar {
		width: 5.625px;
		margin: 0 4.5px 0 7.875px; }

	.metric-bar-popup {
		width: 11.25px;
		margin: 0 6.75px 0 9px; }

	.metric-number {
		padding-top: 0px; }

	.metric-name {
		padding-top: 0px; }

	.metric-tick {
		margin-left: 2.25px;
		width: 9px;
		height: 9px; }

	.metric-change {
		top: 0;
		left: 0; } }

@media  (min-width: 1600px) {
	.metric-container {
		padding: 6.25px 0; }

	.metric-details {
		width: calc(100% - 20px); }

	.metric-bar {
		width: 6.25px;
		margin: 0 5px 0 8.75px; }

	.metric-bar-popup {
		width: 12.5px;
		margin: 0 7.5px 0 10px; }

	.metric-number {
		padding-top: 0px; }

	.metric-name {
		padding-top: 0px; }

	.metric-tick {
		margin-left: 2.5px;
		width: 10px;
		height: 10px; }

	.metric-change {
		top: 0;
		left: 0; } }

@media  (min-width: 1920px) {
	.metric-container {
		padding: 7.5px 0; }

	.metric-details {
		width: calc(100% - 24px); }

	.metric-bar {
		width: 7.5px;
		margin: 0 6px 0 10.5px; }

	.metric-bar-popup {
		width: 15px;
		margin: 0 9px 0 12px; }

	.metric-number {
		padding-top: 0px; }

	.metric-name {
		padding-top: 0px; }

	.metric-tick {
		margin-left: 3px;
		width: 12px;
		height: 12px; }

	.metric-change {
		top: 0;
		left: 0; } }

@media  (min-width: 2560px) {
	.metric-container {
		padding: 10px 0; }

	.metric-details {
		width: calc(100% - 32px); }

	.metric-bar {
		width: 10px;
		margin: 0 8px 0 14px; }

	.metric-bar-popup {
		width: 20px;
		margin: 0 12px 0 16px; }

	.metric-number {
		padding-top: 0px; }

	.metric-name {
		padding-top: 0px; }

	.metric-tick {
		margin-left: 4px;
		width: 16px;
		height: 16px; }

	.metric-change {
		top: 0;
		left: 0; } }
