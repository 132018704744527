@import '~styles/mixins.sass';

.complete-container {
	width: 100%;
	height: 100%; }

.header-container {
	width: 100%;
	@include display-flex-center-mixin;
	margin-bottom: 10px; }

.image-container {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden; }

.heading-text-container {
	width: calc(100% - 40px);
	box-sizing: border-box;
	padding-left: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center; }

.content-container {
	width: 100%;
	text-align: left; }

@media ( min-width: 1440px ) {
	.header-container {
		margin-bottom: 11.25px; }

	.image-container {
		width: 45px;
		height: 45px; }

	.heading-text-container {
		width: calc(100% - 45px);
		padding-left: 11.25px; } }

@media ( min-width: 1600px ) {
	.header-container {
		margin-bottom: 12.5px; }

	.image-container {
		width: 50px;
		height: 50px; }

	.heading-text-container {
		width: calc(100% - 50px);
		padding-left: 12.5px; } }

@media ( min-width: 1920px ) {
	.header-container {
		margin-bottom: 15px; }

	.image-container {
		width: 60px;
		height: 60px; }

	.heading-text-container {
		width: calc(100% - 60px);
		padding-left: 15px; } }

@media ( min-width: 2560px ) {
	.header-container {
		margin-bottom: 20px; }

	.image-container {
		width: 80px;
		height: 80px; }

	.heading-text-container {
		width: calc(100% - 80px);
		padding-left: 20px; } }
