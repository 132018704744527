@import '~styles/mixins.sass';

.each-actor-container {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	// overflow: hidden
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 10px; }

.deselect {
	position: absolute;
	top: -7px;
	right: -7px;
	width: 19px;
	height: 19px; }

.actor-image {
	width: 100%;
	height: 100%;
	border-radius: 50%; }

@media ( min-width: 1440px ) {
	.each-actor-container {
		width: 45px;
		height: 45px;
		margin-right: 11.25px; }

	.deselect {
		top: -7.875px;
		right: -7.875px;
		width: 21.375px;
		height: 21.375px; } }

@media ( min-width: 1600px ) {
	.each-actor-container {
		width: 50px;
		height: 50px;
		margin-right: 12.5px; }

	.deselect {
		position: absolute;
		top: -8.75px;
		right: -8.75px;
		width: 23.75px;
		height: 23.75px; } }

@media ( min-width: 1920px ) {
	.each-actor-container {
		width: 60px;
		height: 60px;
		margin-right: 15px; }

	.deselect {
		top: -10.5px;
		right: -10.5px;
		width: 28.5px;
		height: 28.5px; } }

@media ( min-width: 2560px ) {
	.each-actor-container {
		width: 80px;
		height: 80px;
		margin-right: 20px; }

	.deselect {
		position: absolute;
		top: -14px;
		right: -14px;
		width: 38px;
		height: 38px; } }
