@import '~styles/mixins.sass';

.container {
	width: 337px;
	background: white;
	margin-right: 100px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px; }

.header-container {
	width: 100%;
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
	position: relative;
	padding: 10px 12px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }

.close-container {
	position: absolute;
	width: 12px;
	height: 12px;
	top: 12px;
	right: 12px;
	cursor: pointer; }

.header-image {
	width: 17px;
	height: 24px; }

.header-text {
	padding-top: 4px;
	padding-left: 10px; }

.events-container {
	width: 100%;
	min-height: 200px;
	max-height: 350px;
	overflow-y: auto;
	padding: 10px 0; }

.actions-container {
	width: 100%;
	padding: 7px;
	box-sizing: border-box; }

.actions-header-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer; }

.actions-header-text {
	padding-right: 7px; }

.actions-header-arrow {
	width: 12px;
	height: 12px; }

.actions-list {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap; }

.each-action {
	padding: 4px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	margin: 10px 7px 0px 2px;
	cursor: pointer; }

.static-action {
	width: 65%;
	padding: 4px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	margin: 10px 7px 0px 2px;
	cursor: pointer; }

.action-image {
	width: 24px;
	height: 24px; }

.action-text {
	padding-left: 7px;
	line-height: 1; }

.message-container-left {
	width: 80%;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start; }


.left-arrow {
	width: 10px;
	height: 10px;
	box-sizing: border-box; }

.left-message {
	max-width: 100%;
	padding: 7px;
	border-top-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	align-self: stretch; }

.message-container-right {
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end; }

.right-arrow {
	width: 10px;
	height: 10px;
	box-sizing: border-box; }

.right-arrow-flat {
	width: 15px;
	margin: auto 0px 10px; }

.right-message {
	max-width: 80%;
	padding: 7px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px; }

.body-metrics {
	width: 80%;
	display: flex;
	box-sizing: border-box;
	border-radius: 22px;
	padding: 10px;
	margin: 10px;
	justify-content: space-between; }

.metric-bar {
	margin-left: 20px;
	width: 16px;
	height: 72px; }

.initiative-value-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0px 10px; }

.initiative-value {
	color: #835AC5; }

.initiative-diff {
	margin-right: auto;
	padding: 2px 5px;
	font-family: 'Acherus Militant 1';
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 12px;
	color: #FFFFFF;
	border-radius: 11px; }

.initiative-name {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: bold;
	font-size: 8px;
	text-transform: uppercase;
	line-height: 11px;
	color: #14324B;
	opacity: 0.8; }

.ecosystem-svg {
	width: 18px;
	height: 18px; }

@media  (min-width: 1440px) {
	.container {
		width: 379.125px;
		margin-right: 112.5px;
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px; }

	.header-container {
		border-top-left-radius: 10.125px;
		border-top-right-radius: 10.125px;
		padding: 11.25px 13.5px; }

	.close-container {
		width: 13.5px;
		height: 13.5px;
		top: 13.5px;
		right: 13.5px; }

	.header-image {
		width: 19.125px;
		height: 27px; }

	.header-text {
		padding-top: 4.5px;
		padding-left: 11.25px; }

	.events-container {
		min-height: 225px;
		max-height: 393.75px;
		padding: 11.25px 0; }

	.actions-container {
		padding: 7.875px; }

	.actions-header-text {
		padding-right: 7.875px; }

	.actions-header-arrow {
		width: 13.5px;
		height: 13.5px; }

	.each-action {
		padding: 4.5px 13.5px;
		border-radius: 22.5px;
		margin: 11.25px 7.875px 0px 2.25px; }

	.static-action {
		padding: 4.5px 13.5px;
		border-radius: 22.5px;
		margin: 11.25px 7.875px 0px 2.25px; }

	.action-image {
		width: 27px;
		height: 27px; }

	.action-text {
		padding-left: 7.875px; }

	.message-container-left {
		padding: 11.25px; }

	.left-arrow {
		width: 11.25px;
		height: 11.25px; }

	.left-message {
		padding: 7.875px;
		border-top-right-radius: 5.625px;
		border-bottom-left-radius: 5.625px;
		border-bottom-right-radius: 5.625px; }

	.message-container-right {
		padding: 11.25px; }

	.right-arrow {
		width: 11.25px;
		height: 11.25px; }

	.right-arrow-flat {
		width: 16.875px;
		margin: auto 0px 11.25px; }

	.right-message {
		padding: 7.875px;
		border-top-left-radius: 5.625px;
		border-bottom-left-radius: 5.625px;
		border-bottom-right-radius: 5.625px; }

	.body-metrics {
		border-radius: 24.75px;
		padding: 11.25px;
		margin: 11.25px; }

	.metric-bar {
		margin-left: 22.5px;
		width: 18px;
		height: 81px; }

	.initiative-value-container {
		margin: 0px 11.25px; }

	.initiative-diff {
		padding: 2.25px 5.625px;
		font-size: 13.5px;
		line-height: 13.5px;
		border-radius: 12.375px; }

	.initiative-name {
		font-size: 9px;
		line-height: 12.375px; }

	.ecosystem-svg {
		width: 20.25px;
		height: 20.25px; } }

@media  (min-width: 1600px) {
	.container {
		width: 421.25px;
		margin-right: 125px;
		border-top-left-radius: 12.5px;
		border-top-right-radius: 12.5px; }

	.header-container {
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px;
		padding: 12.5px 15px; }

	.close-container {
		width: 15px;
		height: 15px;
		top: 15px;
		right: 15px; }

	.header-image {
		width: 21.25px;
		height: 30px; }

	.header-text {
		padding-top: 5px;
		padding-left: 12.5px; }

	.events-container {
		min-height: 250px;
		max-height: 437.5px;
		padding: 12.5px 0; }

	.actions-container {
		padding: 8.75px; }

	.actions-header-text {
		padding-right: 8.75px; }

	.actions-header-arrow {
		width: 15px;
		height: 15px; }

	.each-action {
		padding: 5px 15px;
		border-radius: 25px;
		margin: 12.5px 8.75px 0px 2.5px; }

	.static-action {
		padding: 5px 15px;
		border-radius: 25px;
		margin: 12.5px 8.75px 0px 2.5px; }

	.action-image {
		width: 30px;
		height: 30px; }

	.action-text {
		padding-left: 8.75px; }

	.message-container-left {
		padding: 12.5px; }

	.left-arrow {
		width: 12.5px;
		height: 12.5px; }

	.left-message {
		padding: 8.75px;
		border-top-right-radius: 6.25px;
		border-bottom-left-radius: 6.25px;
		border-bottom-right-radius: 6.25px; }

	.message-container-right {
		padding: 12.5px; }

	.right-arrow {
		width: 12.5px;
		height: 12.5px; }

	.right-arrow-flat {
		width: 18.75px;
		margin: auto 0px 12.5px; }

	.right-message {
		padding: 8.75px;
		border-top-left-radius: 6.25px;
		border-bottom-left-radius: 6.25px;
		border-bottom-right-radius: 6.25px; }

	.body-metrics {
		border-radius: 27.5px;
		padding: 12.5px;
		margin: 12.5px; }

	.metric-bar {
		margin-left: 25px;
		width: 20px;
		height: 90px; }

	.initiative-value-container {
		margin: 0px 12.5px; }

	.initiative-diff {
		padding: 2.5px 6.25px;
		font-size: 15px;
		line-height: 15px;
		border-radius: 13.75px; }

	.initiative-name {
		font-size: 10px;
		line-height: 13.75px; }

	.ecosystem-svg {
		width: 22.5px;
		height: 22.5px; } }

@media  (min-width: 1920px) {
	.container {
		width: 505.5px;
		margin-right: 150px;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px; }

	.header-container {
		border-top-left-radius: 13.5px;
		border-top-right-radius: 13.5px;
		padding: 15px 18px; }

	.close-container {
		width: 18px;
		height: 18px;
		top: 18px;
		right: 18px; }

	.header-image {
		width: 25.5px;
		height: 36px; }

	.header-text {
		padding-top: 6px;
		padding-left: 15px; }

	.events-container {
		min-height: 300px;
		max-height: 525px;
		padding: 15px 0; }

	.actions-container {
		padding: 10.5px; }

	.actions-header-text {
		padding-right: 10.5px; }

	.actions-header-arrow {
		width: 18px;
		height: 18px; }

	.each-action {
		padding: 6px 18px;
		border-radius: 30px;
		margin: 15px 10.5px 0px 3px; }

	.static-action {
		padding: 6px 18px;
		border-radius: 30px;
		margin: 15px 10.5px 0px 3px; }

	.action-image {
		width: 36px;
		height: 36px; }

	.action-text {
		padding-left: 10.5px; }

	.message-container-left {
		padding: 15px; }

	.left-arrow {
		width: 15px;
		height: 15px; }

	.left-message {
		padding: 10.5px;
		border-top-right-radius: 7.5px;
		border-bottom-left-radius: 7.5px;
		border-bottom-right-radius: 7.5px; }

	.message-container-right {
		padding: 15px; }

	.right-arrow {
		width: 15px;
		height: 15px; }

	.right-arrow-flat {
		width: 22.5px;
		margin: auto 0px 15px; }

	.right-message {
		padding: 10.5px;
		border-top-left-radius: 7.5px;
		border-bottom-left-radius: 7.5px;
		border-bottom-right-radius: 7.5px; }

	.body-metrics {
		border-radius: 33px;
		padding: 15px;
		margin: 15px; }

	.metric-bar {
		margin-left: 30px;
		width: 24px;
		height: 108px; }

	.initiative-value-container {
		margin: 0px 15px; }

	.initiative-diff {
		padding: 3px 7.5px;
		font-size: 18px;
		line-height: 18px;
		border-radius: 16.5px; }

	.initiative-name {
		font-size: 12px;
		line-height: 16.5px; }

	.ecosystem-svg {
		width: 27px;
		height: 27px; } }

@media  (min-width: 2560px) {
	.container {
		width: 674.4px;
		margin-right: 200px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px; }

	.header-container {
		border-top-left-radius: 18px;
		border-top-right-radius: 18px;
		padding: 20px 24px; }

	.close-container {
		width: 24px;
		height: 24px;
		top: 24px;
		right: 24px; }

	.header-image {
		width: 34px;
		height: 48px; }

	.header-text {
		padding-top: 8px;
		padding-left: 20px; }

	.events-container {
		min-height: 400px;
		max-height: 700px;
		padding: 20px 0; }

	.actions-container {
		padding: 14px; }

	.actions-header-text {
		padding-right: 14px; }

	.actions-header-arrow {
		width: 24px;
		height: 24px; }

	.each-action {
		padding: 8px 24px;
		border-radius: 40px;
		margin: 20px 14px 0px 4px; }

	.static-action {
		padding: 8px 24px;
		border-radius: 40px;
		margin: 10px 14px 0px 4px; }

	.action-image {
		width: 48px;
		height: 48px; }

	.action-text {
		padding-left: 14px; }

	.message-container-left {
		padding: 20px; }

	.left-arrow {
		width: 20px;
		height: 20px; }

	.left-message {
		padding: 14px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px; }

	.message-container-right {
		padding: 10px; }

	.right-arrow {
		width: 20px;
		height: 20px; }

	.right-arrow-flat {
		width: 30px;
		margin: auto 0px 20px; }

	.right-message {
		padding: 14px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px; }

	.body-metrics {
		border-radius: 44px;
		padding: 20px;
		margin: 20px; }

	.metric-bar {
		margin-left: 40px;
		width: 32px;
		height: 144px; }

	.initiative-value-container {
		margin: 0px 20px; }

	.initiative-diff {
		padding: 4px 10px;
		font-size: 24px;
		line-height: 24px;
		border-radius: 22px; }

	.initiative-name {
		font-size: 16px;
		line-height: 22px; }

	.ecosystem-svg {
		width: 36px;
		height: 36px; } }
