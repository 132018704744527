@import '~styles/mixins.sass';

.container {
	width: 307px;
	background: white;
	margin-right: 100px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px; }

.header-container {
	width: 100%;
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
	position: relative;
	padding: 12px 12px 30px 12px;
	box-sizing: border-box; }

.metrics-container {
	width: 250px;
	height: 46px;
	position: absolute;
	bottom: -23px;
	left: calc(50% - 125px);
	border-radius: 6px;
	padding: 5px 7px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center; }

.close-container {
	position: absolute;
	width: 12px;
	height: 12px;
	top: 12px;
	right: 12px;
	cursor: pointer; }

.close-container:hover {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 10px;
	right: 10px;
	cursor: pointer; }

.details-container {
	width: 100%;
	padding: 35px 0 10px 0;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap; }

.timeline-container {
	width: 100%;
	padding: 10px 0px 5px 0px;
	box-sizing: border-box; }

.header-content {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center; }

.image-container {
	width: 57px;
	height: 57px;
	border-radius: 10px;
	overflow: hidden; }

.actor-details-container {
	width: calc(100% - 57px);
	box-sizing: border-box;
	padding-left: 10px; }

.name {
	width: 100%;
	padding-bottom: 7px; }

.designation {
	width: 100%;
	padding-bottom: 7px; }

.bu-member-container {
	display: flex;
	justify-content: flex-start; }

.bu-member-image {
	width: 12px;
	height: 12px; }

.bu-member-text {
	padding-left: 6px; }

.last-joined {
	width: 100%;
	padding-top: 5px;
	padding-bottom: 10px; }

.timeline-header {
	width: calc(100% - 14px);
	padding-bottom: 5px;
	padding-left: 5px;
	margin: 0 7px;
	box-sizing: border-box; }

.timeline-content {
	width: 100%;
	max-height: 130px;
	// padding: 10px 0
	overflow-y: auto;
	display: flex;
	flex-direction: column-reverse; }

.each-history-item {
	width: 100%;
	box-sizing: border-box;
	padding: 0px 15px 0px 30px; }

.each-history-item-content {
	width: 100%;
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: center; }

.each-history-item-change {
	margin-left: 26px;
	margin-top: 10px;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	@include display-flex-center-mixin; }

.each-history-item-details {
	width: calc(100% - 50px);
	box-sizing: border-box;
	padding: 10px 0 10px 8px; }

.each-history-item-action {
	width: 100%;
	padding-bottom: 3px; }

.each-history-item-message {
	width: 100%;
	padding-bottom: 5px; }

.each-history-item-number {
	position: absolute;
	left: -20px;
	top: 14px;
	width: 42px;
	height: 16px;
	// border-radius: 50%
	box-sizing: border-box;
	@include display-flex-center-mixin;
	line-height: 1; }

.empty-container {
	width: 100%;
	// height: 100%
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center; }

.empty-image {
	width: 40px;
	height: 40px; }

.empty-text {
	padding-top: 5px; }

.each-detail-container {
	width: 100%;
	box-sizing: border-box;
	padding: 0 10px; }

.each-detail-heading-content {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 5px; }

.each-detail-heading-image {
	width: 15px;
	height: 15px; }

.each-detail-heading-text {
	padding-left: 7px; }

.each-detail-value {
	// width: 100%
	padding-bottom: 5px; }

@media ( min-width: 1440px ) {
	.container {
		width: 304.875px;
		margin-right: 112.5px;
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px; }

	.header-container {
		border-top-left-radius: 10.125px;
		border-top-right-radius: 10.125px;
		padding: 13.5px 13.5px 33.75px 13.5px; }

	.metrics-container {
		width: 281.25px;
		height: 51.75px;
		bottom: -25.875px;
		left: calc(50% - 140.625px);
		border-radius: 6.75px;
		padding: 5.625px 7.875px; }

	.close-container {
		width: 13.5px;
		height: 13.5px;
		top: 13.5px;
		right: 13.5px; }

	.close-container:hover {
		width: 18px;
		height: 18px;
		top: 11.25px;
		right: 11.25px; }

	.details-container {
		padding: 39.375px 0 11.25px 0; }

	.timeline-container {
		padding: 11.25px 0px 5.625px 0px; }

	.image-container {
		width: 64.125px;
		height: 64.125px;
		border-radius: 11.25px; }

	.actor-details-container {
		width: calc(100% - 64.125px);
		padding-left: 11.25px; }

	.name {
		padding-bottom: 7.875px; }

	.designation {
		padding-bottom: 7.875px; }

	.bu-member-image {
		width: 13.5px;
		height: 13.5px; }

	.bu-member-text {
		padding-left: 6.75px; }

	.last-joined {
		padding-top: 5.625px;
		padding-bottom: 11.25px; }

	.timeline-header {
		width: calc(100% - 15.75px);
		padding-bottom: 5.625px;
		padding-left: 5.625px;
		margin: 0 7.875px; }

	.timeline-content {
		max-height: 146.25px; }

	.each-history-item {
		padding: 0px 16.875px 0px 33.75px; }

	.each-history-item-change {
		margin-left: 29.25px;
		margin-top: 11.25px;
		width: 27px;
		height: 27px;
		border-radius: 4.5px; }

	.each-history-item-details {
		width: calc(100% - 56.25px);
		padding: 15px 0 11.25px 9px; }

	.each-history-item-action {
		padding-bottom: 3.375px; }

	.each-history-item-message {
		padding-bottom: 5.625px; }

	.each-history-item-number {
		left: -22.5px;
		top: 15.75px;
		width: 47.25px;
		height: 18px; }

	.empty-container {
		padding: 22.5px 0; }

	.empty-image {
		width: 45px;
		height: 45px; }

	.empty-text {
		padding-top: 5.625px; }

	.each-detail-container {
		padding: 0 11.25px; }

	.each-detail-heading-content {
		padding-bottom: 5.625px; }

	.each-detail-heading-image {
		width: 16.875px;
		height: 16.875px; }

	.each-detail-heading-text {
		padding-left: 7.875px; }

	.each-detail-value {
		// width: 100%
		padding-bottom: 5.625px; } }

@media ( min-width: 1600px ) {
	.container {
		width: 383.75px;
		margin-right: 125px;
		border-top-left-radius: 12.5px;
		border-top-right-radius: 12.5px; }

	.header-container {
		border-top-left-radius: 11.25px;
		border-top-right-radius: 11.25px;
		padding: 15px 15px 37.5px 15px; }

	.metrics-container {
		width: 312.5px;
		height: 57.5px;
		bottom: -28.75px;
		left: calc(50% - 156.25px);
		border-radius: 7.5px;
		padding: 6.25px 8.75px; }

	.close-container {
		width: 16.25px;
		height: 16.25px;
		top: 16.25px;
		right: 16.25px; }

	.close-container:hover {
		width: 20px;
		height: 20px;
		top: 12.5px;
		right: 12.5px; }

	.details-container {
		padding: 43.75px 0 12.5px 0; }

	.timeline-container {
		padding: 12.5px 0px 6.25px 0px; }

	.image-container {
		width: 71.25px;
		height: 71.25px;
		border-radius: 12.5px; }

	.actor-details-container {
		width: calc(100% - 71.25px);
		padding-left: 12.5px; }

	.name {
		padding-bottom: 8.75px; }

	.designation {
		padding-bottom: 8.75px; }

	.bu-member-image {
		width: 15px;
		height: 15px; }

	.bu-member-text {
		padding-left: 7.5px; }

	.last-joined {
		padding-top: 6.25px;
		padding-bottom: 12.5px; }

	.timeline-header {
		width: calc(100% - 17.5px);
		padding-bottom: 6.25px;
		padding-left: 6.25px;
		margin: 0 8.75px; }

	.timeline-content {
		max-height: 162.5px; }

	.each-history-item {
		padding: 0px 18.75px 0px 37.5px; }

	.each-history-item-change {
		margin-left: 32.5px;
		margin-top: 12.5px;
		width: 30px;
		height: 30px;
		border-radius: 5px; }

	.each-history-item-details {
		width: calc(100% - 62.5px);
		padding: 12.5px 0 12.5px 10px; }

	.each-history-item-action {
		padding-bottom: 3.75px; }

	.each-history-item-message {
		padding-bottom: 6.25px; }

	.each-history-item-number {
		left: -25px;
		top: 17.5px;
		width: 52.5px;
		height: 20px; }

	.empty-container {
		padding: 25px 0; }

	.empty-image {
		width: 50px;
		height: 50px; }

	.empty-text {
		padding-top: 6.25px; }

	.each-detail-container {
		padding: 0 12.5px; }

	.each-detail-heading-content {
		padding-bottom: 6.25px; }

	.each-detail-heading-image {
		width: 18.75px;
		height: 18.75px; }

	.each-detail-heading-text {
		padding-left: 8.75px; }

	.each-detail-value {
		// width: 100%
		padding-bottom: 6.25px; } }

@media ( min-width: 1920px ) {
	.container {
		width: 460.5px;
		margin-right: 150px;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px; }

	.header-container {
		border-top-left-radius: 13.5px;
		border-top-right-radius: 13.5px;
		padding: 18px 18px 45px 18px; }

	.metrics-container {
		width: 375px;
		height: 69px;
		bottom: -34.5px;
		left: calc(50% - 187.5px);
		border-radius: 9px;
		padding: 7.5px 10.5px; }

	.close-container {
		width: 18px;
		height: 18px;
		top: 18px;
		right: 18px; }

	.close-container:hover {
		width: 24px;
		height: 24px;
		top: 15px;
		right: 15px; }

	.details-container {
		padding: 52.5px 0 15px 0; }

	.timeline-container {
		padding: 15px 0px 7.5px 0px; }

	.image-container {
		width: 85.5px;
		height: 85.5px;
		border-radius: 15px; }

	.actor-details-container {
		width: calc(100% - 85.5px);
		padding-left: 15px; }

	.name {
		padding-bottom: 10.5px; }

	.designation {
		padding-bottom: 10.5px; }

	.bu-member-image {
		width: 18px;
		height: 18px; }

	.bu-member-text {
		padding-left: 9px; }

	.last-joined {
		padding-top: 7.5px;
		padding-bottom: 15px; }

	.timeline-header {
		width: calc(100% - 21px);
		padding-bottom: 7.5px;
		padding-left: 7.5px;
		margin: 0 10.5px; }

	.timeline-content {
		max-height: 195px; }

	.each-history-item {
		padding: 0px 22.5px 0px 45px; }

	.each-history-item-change {
		margin-left: 39px;
		margin-top: 15px;
		width: 36px;
		height: 36px;
		border-radius: 6px; }

	.each-history-item-details {
		width: calc(100% - 75px);
		padding: 15px 0 15px 12px; }

	.each-history-item-action {
		padding-bottom: 4.5px; }

	.each-history-item-message {
		padding-bottom: 7.5px; }

	.each-history-item-number {
		left: -30px;
		top: 21px;
		width: 63px;
		height: 24px; }

	.empty-container {
		padding: 30px 0; }

	.empty-image {
		width: 60px;
		height: 60px; }

	.empty-text {
		padding-top: 7.5px; }

	.each-detail-container {
		padding: 0 15px; }

	.each-detail-heading-content {
		padding-bottom: 7.5px; }

	.each-detail-heading-image {
		width: 22.5px;
		height: 22.5px; }

	.each-detail-heading-text {
		padding-left: 10.5px; }

	.each-detail-value {
		// width: 100%
		padding-bottom: 7.5px; } }


@media ( min-width: 2560px ) {
	.container {
		width: 614px;
		margin-right: 200px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px; }

	.header-container {
		border-top-left-radius: 18px;
		border-top-right-radius: 18px;
		padding: 24px 24px 60px 24px; }

	.metrics-container {
		width: 500px;
		height: 92px;
		bottom: -46px;
		left: calc(50% - 250px);
		border-radius: 12px;
		padding: 10px 14px; }

	.close-container {
		width: 26px;
		height: 26px;
		top: 26px;
		right: 26px; }

	.close-container:hover {
		width: 32px;
		height: 32px;
		top: 20px;
		right: 20px; }

	.details-container {
		padding: 70px 0 20px 0; }

	.timeline-container {
		padding: 20px 0px 10px 0px; }

	.image-container {
		width: 114px;
		height: 114px;
		border-radius: 20px; }

	.actor-details-container {
		width: calc(100% - 114px);
		padding-left: 20px; }

	.name {
		padding-bottom: 14px; }

	.designation {
		padding-bottom: 14px; }

	.bu-member-image {
		width: 24px;
		height: 24px; }

	.bu-member-text {
		padding-left: 12px; }

	.last-joined {
		padding-top: 10px;
		padding-bottom: 20px; }

	.timeline-header {
		width: calc(100% - 28px);
		padding-bottom: 10px;
		padding-left: 10px;
		margin: 0 14px; }

	.timeline-content {
		max-height: 260.4px; }

	.each-history-item {
		padding: 0px 30px 0px 60px; }

	.each-history-item-change {
		margin-left: 52px;
		margin-top: 20px;
		width: 48px;
		height: 48px;
		border-radius: 8px; }

	.each-history-item-details {
		width: calc(100% - 100px);
		padding: 20px 0 20px 16px; }

	.each-history-item-action {
		padding-bottom: 6px; }

	.each-history-item-message {
		padding-bottom: 10px; }

	.each-history-item-number {
		left: -40px;
		top: 28px;
		width: 84px;
		height: 32px; }

	.empty-container {
		padding: 40px 0; }

	.empty-image {
		width: 80px;
		height: 80px; }

	.empty-text {
		padding-top: 10px; }

	.each-detail-container {
		padding: 0 20px; }

	.each-detail-heading-content {
		padding-bottom: 10px; }

	.each-detail-heading-image {
		width: 30px;
		height: 30px; }

	.each-detail-heading-text {
		padding-left: 14px; }

	.each-detail-value {
		// width: 100%
		padding-bottom: 10px; } }
