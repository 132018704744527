@import '~styles/mixins.sass';

.objectives-container {
	// height: 100%
	padding: 0 10px;
	@include display-flex-center-mixin; }

.objectives-image-container {
	width: 24px;
	height: 24px;
	@include display-flex-center-mixin;
	border-radius: 50%;
	cursor: pointer; }

.objectives-image {
	width: 14px;
	height: 14px; }

.objectives-text-container {
	padding: 0 20px 0 5px;
	@include display-flex-center-mixin; }

.show-objectives-hover {
	display: flex;
	justify-content: space-around;
	align-items: center;
	position : absolute;
	top : 50px;
	padding: 2px 8px;
	border-radius: 6px;
	text-align: center;
	height: 20px;
	z-index: 2; }

.arrow {
	width: 6px;
	height: 6px;
	position: absolute;
	right: 88.5px;
	bottom: 0px;
	box-sizing: border-box; }

@media ( min-width: 1440px ) {
	.objectives-container {
		padding: 0 11.25px; }

	.objectives-image-container {
		width: 27px;
		height: 27px; }

	.objectives-image {
		width: 15.75px;
		height: 15.75px; }

	.objectives-text-container {
		padding: 0 22.5px 0 5.625px; }

	.show-objectives-hover {
		top: 56.25px;
		padding: 2.25px 9px;
		border-radius: 6.75px;
		height: 22.5px; }

	.arrow {
		width: 6.75px;
		height: 6.75px;
		right: 92px; } }

@media ( min-width: 1600px ) {
	.objectives-container {
		padding: 0 12.5px; }

	.objectives-image-container {
		width: 30px;
		height: 30px; }

	.objectives-image {
		width: 17.5px;
		height: 17.5px; }

	.objectives-text-container {
		padding: 0 25px 0 6.25px; }

	.show-objectives-hover {
		top : 62.5px;
		padding: 2.5px 10px;
		border-radius: 7.5px;
		height: 25px; }

	.arrow {
		width: 7.5px;
		height: 7.5px;
		right: 110.6px; } }

@media ( min-width: 1920px ) {
	.objectives-container {
		padding: 0 15px; }

	.objectives-image-container {
		width: 36px;
		height: 36px; }

	.objectives-image {
		width: 21px;
		height: 21px; }

	.objectives-text-container {
		padding: 0 30px 0 7.5px; }

	.show-objectives-hover {
		top: 75px;
		padding: 3px 12px;
		border-radius: 9px;
		height: 30px; }

	.arrow {
		width: 9px;
		height: 9px;
		right: 124px; } }

@media ( min-width: 2560px ) {
	.objectives-container {
		padding: 0 20px; }

	.objectives-image-container {
		width: 48px;
		height: 48px; }

	.objectives-image {
		width: 28px;
		height: 28px; }

	.objectives-text-container {
		padding: 0 40px 0 10px; }

	.show-objectives-hover {
		top : 100px;
		padding: 4px 16px;
		border-radius: 12px;
		height: 40px; }

	.arrow {
		width: 12px;
		height: 12px;
		right: 157.5px; } }
