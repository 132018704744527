@import '~styles/mixins.sass';

.metricPopup-container {
	width: 1100px;
	height: 600px;
	overflow: hidden;
	border-radius: 5px; }

.header-container {
	width: 100%;
	height: 44px;
	display: flex; }

.header-line {
	width: calc(100% - 8px);
	height: 1px;
	margin: 0 4px; }

.header-content {
	width: calc(100% - 44px);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding-left: 10px; }

.header-close {
	width: 44px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 5px;
	cursor: pointer; }

.content-container {
	width: 100%;
	height: calc(100% - 45px);
	box-sizing: border-box;
	padding: 0 30px 20px 30px; }

.close-image {
	width: 17px;
	height: 18px; }

.graphs-box {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	padding-top: 20px; }

.each-graph-box {
	width: 31%; }

.empty-container {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin; }

@media ( min-width: 1440px ) {
	.metricPopup-container {
		width: 1237.5px;
		height: 675px;
		border-radius: 5.625px; }

	.header-container {
		height: 49.5px; }

	.header-line {
		width: calc(100% - 9px);
		height: 1.125px;
		margin: 0 4.5px; }

	.header-content {
		width: calc(100% - 49.5px);
		padding-left: 11.25px; }

	.header-close {
		width: 49.5px;
		border-bottom-left-radius: 5.625px; }

	.content-container {
		height: calc(100% - 50.625px);
		padding: 0 33.75px 22.5px 33.75px; }

	.close-image {
		width: 19.125px;
		height: 20.25px; }

	.graphs-box {
		padding-top: 22.5px; } }

@media ( min-width: 1600px ) {
	.metricPopup-container {
		width: 1375px;
		height: 750px;
		border-radius: 6.25px; }

	.header-container {
		height: 55px; }

	.header-line {
		width: calc(100% - 10px);
		height: 1.25px;
		margin: 0 5px; }

	.header-content {
		width: calc(100% - 55px);
		padding-left: 12.5px; }

	.header-close {
		width: 55px;
		border-bottom-left-radius: 6.25px; }

	.content-container {
		height: calc(100% - 56.25px);
		padding: 0 37.5px 25px 37.5px; }

	.close-image {
		width: 21.25px;
		height: 22.5px; }

	.graphs-box {
		padding-top: 25px; } }

@media ( min-width: 1920px ) {
	.metricPopup-container {
		width: 1650px;
		height: 900px;
		border-radius: 7.5px; }

	.header-container {
		height: 66px; }

	.header-line {
		width: calc(100% - 12px);
		height: 1.5px;
		margin: 0 6px; }

	.header-content {
		width: calc(100% - 66px);
		padding-left: 15px; }

	.header-close {
		width: 66px;
		border-bottom-left-radius: 7.5px; }

	.content-container {
		height: calc(100% - 67.5px);
		padding: 0 45px 30px 45px; }

	.close-image {
		width: 25.5px;
		height: 27px; }

	.graphs-box {
		padding-top: 30px; } }

@media ( min-width: 2560px ) {
	.metricPopup-container {
		width: 2200px;
		height: 1200px;
		border-radius: 10px; }

	.header-container {
		height: 88px; }

	.header-line {
		width: calc(100% - 16px);
		height: 2px;
		margin: 0 8px; }

	.header-content {
		width: calc(100% - 88px);
		padding-left: 20px; }

	.header-close {
		width: 88px;
		border-bottom-left-radius: 10px; }

	.content-container {
		height: calc(100% - 90px);
		padding: 0 60px 40px 60px; }

	.close-image {
		width: 34px;
		height: 36px; }

	.graphs-box {
		padding-top: 40px; } }
