@import '~styles/mixins.sass';

.timer-container {
	height: 100%;
	width: 100px;
	display: flex;
	flex-direction: column;
	margin-right: 15px; }


.timer-text {
	padding-top: 4px; }

.timer-content {
	height: 100%;
	padding-top: 4px;
	box-sizing: border-box; }

.time-reminder {
	width: 75px;
	height: 3px;
	background: #FD5382;
	margin-left: 2px; }

@media ( min-width: 1440px ) {
	.timer-container {
		width: 112.5px;
		margin-right: 16.875px; }

	.timer-text {
		padding-top: 4.5px; }

	.timer-content {
		padding-top: 4.5px; }

	.time-reminder {
		width: 84.375px;
		height: 3.375px;
		margin-left: 2.25px; } }

@media ( min-width: 1600px ) {
	.timer-container {
		width: 125px;
		margin-right: 18.75px; }

	.timer-text {
		padding-top: 5px; }

	.timer-content {
		padding-top: 5px; }

	.time-reminder {
		width: 93.75px;
		height: 3.75px;
		margin-left: 2.5px; } }

@media ( min-width: 1920px ) {
	.timer-container {
		width: 150px;
		margin-right: 22.5px; }

	.timer-text {
		padding-top: 6px; }

	.timer-content {
		padding-top: 6px; }

	.time-reminder {
		width: 112.5px;
		height: 4.5px;
		margin-left: 3px; } }

@media ( min-width: 2560px ) {
	.timer-container {
		width: 200px;
		margin-right: 30px; }

	.timer-text {
		padding-top: 8px; }

	.timer-content {
		padding-top: 8px; }

	.time-reminder {
		width: 150px;
		height: 6px;
		margin-left: 4px; } }
