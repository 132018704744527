.desktop-router {
    width: 100%;
    height: calc(100% - 50px); }

@media ( min-width: 1440px ) {
    .desktop-router {
     	height: calc(100% - 56.25px); } }

@media ( min-width: 1600px ) {
    .desktop-router {
     	height: calc(100% - 62.5px); } }

@media ( min-width: 1920px ) {
    .desktop-router {
     	height: calc(100% - 75px); } }

@media ( min-width: 2560px ) {
    .desktop-router {
     	height: calc(100% - 100px); } }

