@import '~styles/mixins.sass';

.sim-logo-container {
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.sim-logo {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-right: 30px; }

.sim-name {
	padding-left: 5px; }

.sim-image {
	width: 201px;
	height: 36px; }

@media ( min-width: 1440px ) {
	.sim-logo {
		padding-right: 33.75px; }

	.sim-name {
		padding-left: 5.625px; }

	.sim-image {
		width: 226.125px;
		height: 40.5px; } }

@media ( min-width: 1600px ) {
	.sim-logo {
		padding-right: 37.5px; }

	.sim-name {
		padding-left: 6.25px; }

	.sim-image {
		width: 251.25px;
		height: 45px; } }

@media ( min-width: 1920px ) {
	.sim-logo {
		padding-right: 45px; }

	.sim-name {
		padding-left: 7.5px; }

	.sim-image {
		width: 301.5px;
		height: 54px; } }

@media ( min-width: 2560px ) {
	.sim-logo {
		padding-right: 60px; }

	.sim-name {
		padding-left: 10px; }

	.sim-image {
		width: 402px;
		height: 72px; } }
