@import '~styles/mixins.sass';

.speaker-card-container {
	// margin: 0 10px 10px 0
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	// padding: 0px 7px 5px 7px
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-radius: 4px; }

.title-container {
	width: 100%;
	height: 80px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	box-sizing: border-box;
	padding: 5px 10px; }

.image {
	width: 60px;
	height: 60px;
	border-radius: 10px;
	overflow: hidden; }

.title-content {
	width: calc(100% - 60px);
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-sizing: border-box;
	padding-left: 10px; }

.title {
	width: 100%;
	text-align: left; }

.designation {
	width: 100%;
	padding-top: 5px;
	text-align: left; }

.title-line {
	width: 65%;
	height: 1px; }

.content {
	width: 100%;
	height: calc(100% - 80px); }

.point {
	width: 100%;
	box-sizing: border-box;
	padding: 6px 0px 2px 25px;
	position: relative; }

@media ( min-width: 1440px ) {
	.speaker-card-container {
		border-radius: 4.5px; }

	.title-container {
		height: 90px;
		padding: 5.625px 11.25px; }

	.image {
		width: 67.5px;
		height: 67.5px;
		border-radius: 11.25px; }

	.title-content {
		width: calc(100% - 67.5px);
		padding-left: 11.25px; }

	.designation {
		padding-top: 5.625px; }

	.title-line {
		height: 1.125px; }

	.content {
		height: calc(100% - 90px); }

	.point {
		padding: 6.75px 0px 2.25px 28.125px; } }

@media ( min-width: 1600px ) {
	.speaker-card-container {
		border-radius: 5px; }

	.title-container {
		height: 100px;
		padding: 6.25px 12.5px; }

	.image {
		width: 75px;
		height: 75px;
		border-radius: 12.5px; }

	.title-content {
		width: calc(100% - 75px);
		padding-left: 12.5px; }

	.designation {
		padding-top: 6.25px; }

	.title-line {
		height: 1.25px; }

	.content {
		height: calc(100% - 100px); }

	.point {
		padding: 7.5px 0px 2.5px 31.25px; } }

@media ( min-width: 1920px ) {
	.speaker-card-container {
		border-radius: 6px; }

	.title-container {
		height: 120px;
		padding: 7.5px 15px; }

	.image {
		width: 90px;
		height: 90px;
		border-radius: 15px; }

	.title-content {
		width: calc(100% - 90px);
		padding-left: 15px; }

	.designation {
		padding-top: 7.5px; }

	.title-line {
		height: 1.5px; }

	.content {
		height: calc(100% - 120px); }

	.point {
		padding: 9px 0px 3px 37.5px; } }

@media ( min-width: 2560px ) {
	.speaker-card-container {
		border-radius: 8px; }

	.title-container {
		height: 160px;
		padding: 10px 20px; }

	.image {
		width: 120px;
		height: 120px;
		border-radius: 20px; }

	.title-content {
		width: calc(100% - 120px);
		padding-left: 20px; }

	.designation {
		padding-top: 10px; }

	.title-line {
		height: 2px; }

	.content {
		height: calc(100% - 160px); }

	.point {
		padding: 12px 0px 4px 50px; } }
