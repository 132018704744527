// Open Sans Font

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-ExtraBold.ttf') format("truetype");
	font-weight: 800;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Bold.ttf') format("truetype"), url('./opensans/OpenSans-Bold.woff') format("woff"), url('./opensans/OpenSans-Bold.eot');
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Semibold.ttf') format("truetype"), url('./opensans/OpenSans-Semibold.woff') format("woff"), url('./opensans/OpenSans-Semibold.eot');
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Regular.ttf') format("truetype"), url('./opensans/OpenSans-Regular.woff') format("woff"), url('./opensans/OpenSans-Regular.eot');
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: 'Open Sans';
	src: url('./opensans/OpenSans-Light.ttf') format("truetype"), url('./opensans/OpenSans-Light.woff') format("woff"), url('./opensans/OpenSans-Light.eot');
	font-weight: 300;
	font-style: normal; }


// LibreBaskerville

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(./libreBaskerville/LibreBaskerville-Regular.ttf) format("truetype"), url('./opensans/OpenSans-Regular.woff') format("woff"), url('./opensans/OpenSans-Regular.eot'); }


@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url(./libreBaskerville/LibreBaskerville-Bold.ttf) format("truetype"), url('./opensans/OpenSans-Bold.woff') format("woff"), url('./opensans/OpenSans-Bold.eot'); }


//Digital

@font-face {
  font-family: 'Digital';
  src: url('./digital/digital-7.ttf') format("truetype");
  font-style: normal;
  font-weight: 300; }

// Acherus Militant

@font-face {
	font-family: 'Acherus Militant';
	src: url('./acherusMilitant/acherus_militant-light.otf') format("opentype");
	font-style: normal;
	font-weight: 400; }

@font-face {
	font-family: 'Acherus Militant';
	src: url('./acherusMilitant/acherus_militant-bold.otf') format("opentype");
	font-style: normal;
	font-weight: 700; }


// open sans condensed

@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 300;
	src: url('./opensanscondensed/light-latin-ext.woff2') format('woff2'); }

@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 300;
	src: url('./opensanscondensed/light-latin.woff2') format('woff2'); }

@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./opensanscondensed/bold-latin-ext.woff2') format('woff2'); }

@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./opensanscondensed/bold-latin.woff2') format('woff2'); }


// Montserrat Font

@font-face {
	font-family: 'Montserrat';
	src: url('./montserrat/Montserrat-Bold.ttf') format("truetype");
	font-weight: 700;
	font-style: normal; }

@font-face {
	font-family: 'Montserrat';
	src: url('./montserrat/Montserrat-SemiBold.ttf') format("truetype");
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'Montserrat';
	src: url('./montserrat/Montserrat-Regular.ttf') format("truetype");
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: 'Montserrat';
	src: url('./montserrat/Montserrat-Light.ttf') format("truetype");
	font-weight: 300;
	font-style: normal; }
