@import '~styles/mixins.sass';

.container {
	width: 640px;
	height: 360px;
	overflow: hidden;
	border-radius: 5px;
	position: relative;
	background-color: #FFF;
	border-radius: 10px;
	display: flex;
	justify-content: center; }

.close-container {
	height: 42px;
	width: 42px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: 10px;
	cursor: pointer;
	padding: 10px;
	background-color: #835AC5;
	box-sizing: border-box;
	position: absolute;
	right: -1px;
	top: -1px;
	z-index: 1; }

.unlock-container {
	position: absolute;
	top: 10%;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center; }

.confetti-container {
	position: absolute;
	top: 0px;
	height: 40%;
	width: 100%;
	display: flex;
	justify-content: center; }

.content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 40%;
	width: 100%; }

.title {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 27px;
	text-align: center;
	color: #14324B;
	margin: 10px; }

.description {
	width: 80%;
	font-family: Open Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 164.5%;
	text-align: center;
	color: #14324B;
	opacity: 0.8; }

.button {
	cursor: pointer;
	background: #835AC5;
	opacity: 0.9;
	border: 1px solid #835AC5;
	border-radius: 4px;
	width: 220px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px; }

.button-text {
	font-family: Open Sans;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #F7F7F9;
	border-radius: 35px; }

@media  (min-width: 1440px) {
	.container {
		width: 720px;
		height: 405px;
		border-radius: 5.625px;
		border-radius: 11.25px; }

	.close-container {
		height: 47.25px;
		width: 47.25px;
		border-top-right-radius: 11.25px;
		border-bottom-left-radius: 11.25px;
		padding: 11.25px;
		right: -1.125px;
		top: -1.125px; }

	.unlock-container {
		width: 112.5px;
		height: 112.5px; }

	.title {
		font-size: 22.5px;
		line-height: 30.375px;
		margin: 11.25px; }

	.description {
		font-size: 14.625px; }

	.button {
		border: 1.125px solid #835AC5;
		border-radius: 4.5px;
		width: 247.5px;
		height: 47.25px;
		margin: 11.25px; }

	.button-text {
		font-size: 18px;
		line-height: 24.75px;
		border-radius: 39.375px; } }

@media  (min-width: 1600px) {
	.container {
		width: 800px;
		height: 450px;
		border-radius: 6.25px;
		border-radius: 12.5px; }

	.close-container {
		height: 52.5px;
		width: 52.5px;
		border-top-right-radius: 12.5px;
		border-bottom-left-radius: 12.5px;
		padding: 12.5px;
		right: -1.25px;
		top: -1.25px; }

	.unlock-container {
		width: 125px;
		height: 125px; }

	.title {
		font-size: 25px;
		line-height: 33.75px;
		margin: 12.5px; }

	.description {
		font-size: 16.25px; }

	.button {
		border: 1.25px solid #835AC5;
		border-radius: 5px;
		width: 275px;
		height: 52.5px;
		margin: 12.5px; }

	.button-text {
		font-size: 20px;
		line-height: 27.5px;
		border-radius: 43.75px; } }

@media  (min-width: 1920px) {
	.container {
		width: 960px;
		height: 540px;
		border-radius: 7.5px;
		border-radius: 15px; }

	.close-container {
		height: 63px;
		width: 63px;
		border-top-right-radius: 15px;
		border-bottom-left-radius: 15px;
		padding: 15px;
		right: -1.5px;
		top: -1.5px; }

	.unlock-container {
		width: 150px;
		height: 150px; }

	.title {
		font-size: 30px;
		line-height: 40.5px;
		margin: 15px; }

	.description {
		font-size: 19.5px; }

	.button {
		border: 1.5px solid #835AC5;
		border-radius: 6px;
		width: 330px;
		height: 63px;
		margin: 15px; }

	.button-text {
		font-size: 24px;
		line-height: 33px;
		border-radius: 52.5px; } }

@media  (min-width: 2560px) {
	.container {
		width: 1280px;
		height: 720px;
		border-radius: 10px;
		border-radius: 20px; }

	.close-container {
		height: 84px;
		width: 84px;
		border-top-right-radius: 20px;
		border-bottom-left-radius: 20px;
		padding: 20px;
		right: -2px;
		top: -2px; }

	.unlock-container {
		width: 200px;
		height: 200px; }

	.title {
		font-size: 40px;
		line-height: 54px;
		margin: 20px; }

	.description {
		font-size: 26px; }

	.button {
		border: 2px solid #835AC5;
		border-radius: 8px;
		width: 440px;
		height: 84px;
		margin: 20px; }

	.button-text {
		font-size: 32px;
		line-height: 44px;
		border-radius: 70px; } }
